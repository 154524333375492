import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import {reactLocalStorage} from 'reactjs-localstorage';
import Button from '@material-ui/core/Button';
import Footer from "./Footer.js"
import { Trans } from "react-i18next";

import {PersonAdd, Schedule, Send} from '@material-ui/icons';

import Header from "./Header.js";

class GettingStartedPage extends Component {
    accessCode() {
        reactLocalStorage.set('isGuest', true);
        navigate("/signUp");
    }

  render() {
    return (
        <div>
            <Header user={this.props.user}/>
            <div className="main-content relative">
                <img src="images/bodafoto.jpeg" loading="lazy" alt="" className="home-bg"/>
                <div className="absolute w-full h-full top-0 bg-black bg-opacity-50"></div>
                <div className="absolute w-full h-full top-0">
                    <div className="h-3/5 m-auto text-center text-white lg:mt-40">
                        <div className="text-6xl mb-2 mb-12">Special Day</div>
                        <div className="text-2xl mb-2 mb-12"><Trans i18nKey="specialDesc">Don't just send an invitation, create your own experience app</Trans></div>
                        <div>
                            <div className="font-bold">
                                <Link to="/signUp">
                                    <Button variant="contained" color="primary" sx={{ width: '300px' }}><Trans i18nKey="signUp">Sign up</Trans></Button>
                                </Link>
                            </div>
                            <div className="sm:mx-12 md:mx-32 font-bold my-5">
                                <Button variant="contained" color="primary" onClick={this.accessCode} sx={{ width: '300px' }}><Trans i18nKey="accessCode">Access code</Trans></Button>
                            </div>
                            <div className="font-bold">
                                <Link to="/signIn">
                                    <Button variant="contained" color="primary" sx={{ width: '300px' }}><Trans i18nKey="login">Log in</Trans></Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-10 w-80 mx-auto text-center">
              <div className="text-2xl pb-5"><Trans i18nKey="downloadApp">Download the app</Trans></div>
              <div className="flex">
                <div className="inline-block flex-1"><a target="_blank" href="https://apps.apple.com/us/app/a-special-day/id1380351953"><img width="146" src="images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" loading="lazy" alt=""/></a></div>
                <div className="inline-block flex-1"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.codingpengins.app.ptwedding"><img src="images/Google_Play_Store_badge_EN.svg" loading="lazy" alt=""/></a></div>
              </div>
            </div>
            <div className="py-10">
              <div className="text-center text-2xl pb-5"><Trans i18nKey="home3Steps">Create Your Event In 3 Easy Steps</Trans></div>
              <div className="flex mx-20 text-center">
                <div className="inline-block flex-1 border-indigo-100 border-2 rounded">
                  <div><Schedule className="p-1 my-4 border-indigo-300 border-2 rounded-full" style={{ fontSize: 75 }} color="primary"/></div>
                  <div className="text-xl pb-5"><Trans i18nKey="home1stStep">#1 Enter Your Event Data</Trans></div>
                </div>
                <div className="inline-block flex-1 border-indigo-100 border-2 rounded mx-5">
                  <div><PersonAdd className="p-1 my-4 border-indigo-300 border-2 rounded-full" style={{ fontSize: 75 }} color="primary"/></div>
                  <div className="text-xl pb-5"><Trans i18nKey="home2ndStep">#2 Add Your Guest List</Trans></div>
                </div>
                <div className="inline-block flex-1 border-indigo-100 border-2 rounded">
                  <div><Send className="p-1 my-4 border-indigo-300 border-2 rounded-full" style={{ fontSize: 75 }} color="primary"/></div>
                  <div className="text-xl pb-5"><Trans i18nKey="home3rdStep">#3 Share Your Invitation Link</Trans></div>
                </div>
              </div>
            </div>
            <div className="py-10">
              <div className="text-center text-2xl mb-4"><Trans i18nKey="homeBenefits">And Get The Benefits Of</Trans></div>
              <ul className="text-center">
                <li><Trans i18nKey="homeBenefitsRSVP">Real Time RSVP</Trans></li>
                <li><Trans i18nKey="homeBenefitsSocial">Social Page</Trans></li>
                <li><Trans i18nKey="homeBenefitsNav">One Click Navigation</Trans></li>
                <li><Trans i18nKey="homeBenefitsUber">Uber Integration</Trans></li>
              </ul>
            </div>
            <div className="py-10 bg-gray-600 text-gray-300">
              <div className="text-center text-2xl pb-5"><Trans i18nKey="homeEveryParty">For Every Type of Party</Trans></div>
              <div className="flex">
                <div className="flex-1 mx-5 text-center">
                  <img className="img-fit" src="images/bodafoto7.jpeg" loading="lazy" alt=""/>
                  <div className="text-lg"><Trans i18nKey="wedding">Wedding</Trans></div>
                </div>
                <div className="flex-1 mx-5 text-center">
                  <img className="img-fit" src="images/quinceanos2.jpeg" loading="lazy" alt=""/>
                  <div className="text-lg"><Trans i18nKey="quinceanos">Quince Años</Trans></div>
                </div>
                <div className="flex-1 mx-5 text-center">
                <img className="img-fit" src="images/fotoboda4.jpeg" loading="lazy" alt=""/>
                  <div className="text-lg"><Trans i18nKey="specialParty">Special Party</Trans></div>
                </div>
              </div>
            </div>
            <div className="py-10 pb-60">
              <div className="text-center text-2xl pb-5"><Trans i18nKey="testimonials">Testimonials</Trans></div>
              <div className="flex mx-5 lg:mx-40">
                <div className="flex-1">
                  <div className="text-xl">Braulio Santana</div>
                  <div className="font-bold"><Trans i18nKey="wedding">Wedding</Trans> - Houston, TX</div>
                  <div className="italic"><Trans i18nKey="homeTestimonials1">I'm from Houston and our wedding was on México. Special Day helped us to be in contact with all our guest and had all the information about our  wedding. This app I highly recommend.</Trans></div>
                </div>
                <div className="flex-2">
                  <div><img width="150" src="images/brau.jpeg" /></div>
                </div>
              </div>
              <div className="flex pt-10 mx-5 lg:mx-40">
                <div className="flex-1">
                  <div className="text-xl">Sofía Tovar</div>
                  <div className="font-bold"><Trans i18nKey="quinceanos">Quince Ańos</Trans> - México City, México</div>
                  <div className="italic"><Trans i18nKey="homeTestimonials2">I love this app! It made my life so much easier when I planned my party. All my friends were impressed with the invitation and how easy the registry, location and that it had an uber button in the app.</Trans></div>
                </div>
                <div className="flex-2">
                <div><img width="150" src="images/mandy.jpeg" /></div>
                </div>
              </div>
            </div>
            <Footer></Footer>
        </div>
    ) 
  }
}
export default GettingStartedPage;

