import React, { Component, useState } from "react";
import { Link } from "@reach/router";
import { Trans } from "react-i18next";
import Footer from "./Footer.js"
import TextField from '@material-ui/core/TextField';
import Header from "./Header.js";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../i18n';

const getLanguage = () => {
  const lang = i18n.language;
  const langCode = lang.split('-')[0];
  let currentLang = 'en';
  if(Object.keys(i18n.store.data).includes(langCode)) {
    currentLang = langCode;
  }
  return currentLang;
}

class GettingStartedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          error: '',
          message: '',
        }
    }

    onChangeHandler = (event) => {
        const {id, name, value} = event.currentTarget;
    
        if(name === 'contactUsEmail') {
            this.setState({
                email: value
            });
        }
        else if(id === 'contactUsMsg'){
            this.setState({
                message: value
            });
        }
    };

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
  
    validatePhone = (phone) => {
      const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(String(phone).toLowerCase());
    }

    clickContactUs = () => {
        const { email, message } = this.state;
        const language = getLanguage();
        // check if all forms are filled
        if (email.length === 0 || message.length === 0) {
          this.setState({
            open: true,
            error: i18n.store.data[language].translation.needHelpEmptyFields
          });
          return;
        }
        // check if emailphone is one or the other
        if (!this.validateEmail(email) && !this.validatePhone(email)) {
          this.setState({
            open: true,
            error: i18n.store.data[language].translation.needHelpValidContact
          });
          return;
        }
        const tmpState = {name: 'Special day', emailphone: email, message};
        fetch('https://blue-penguin-d1105.firebaseio.com/contactus.json', {
          method: "POST",
          body: JSON.stringify(tmpState),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then(
          (response) => (response.json())
        ).then((response)=> {
          if (response.name && response.name.length > 0) {
            this.setState({
              email: '',
              message: '',
              open: true,
              error: i18n.store.data[language].translation.needHelpSuccessful
            });
          } else if(response.error === 'Permission denied') {
            this.setState({
              open: true,
              error: i18n.store.data[language].translation.somethingWrong
            });
          } else {
            this.setState({
              open: true,
              error: i18n.store.data[language].translation.somethingWrong
            });
          }
        })
      }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
      const { email, error, message, open } = this.state;
    return (
        <div>
            <Header user={this.props.user}/>
            <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title"><Trans i18nKey="error">Error</Trans></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {error}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  <Trans i18nKey="close">Close</Trans>
                </Button>
              </DialogActions>
            </Dialog>
            <div className="main-content relative">
                <div className="w-full h-full top-0">
                    <div className="h-3/5 m-auto text-gray-500 text-center mt-40">
                        <h1 className="text-4xl mb-2 font-bold mb-12"><Trans i18nKey="needHelp">Need Help</Trans></h1>
                        <div className="pb-10">
                            <div><Trans i18nKey="needHelpTutorialsDesc">Feel free to view our tutorials OR use the form below to contact us</Trans></div>
                            <Link to="/tutorials">
                                <button className="mt-8 bg-blue-500 hover:bg-blue-600 py-2 text-white px-10"><Trans i18nKey="viewTutorials">View tutorials</Trans></button>
                            </Link>
                        </div>
                        <div>
                            <TextField
                                required
                                name="contactUsEmail"
                                label="Email"
                                sx={{ width: 230 }}
                                value={email}
                                onChange={this.onChangeHandler}
                            />
                        </div>
                        <div className="pt-5">
                            <TextField
                                required
                                id="contactUsMsg"
                                label="Message"
                                multiline
                                rows={4}
                                sx={{ width: 230 }}
                                value={message}
                                variant="outlined"
                                onChange={this.onChangeHandler}
                            />
                        </div>
                        <div>
                        <button className="mt-4 bg-blue-500 hover:bg-blue-600 py-2 text-white px-10 mb-20" onClick={() => {
                            this.clickContactUs();
                        }}><Trans i18nKey="submit">Submit</Trans></button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    ) 
  }
}
export default GettingStartedPage;

