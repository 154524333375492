import React, { useContext } from "react";
import { Redirect, Router } from "@reach/router";
import Default from "./Default";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { UserContext } from "../providers/UserProvider";
import ProfilePage from "./ProfilePage";
import Welcome from "./Welcome";
import GettingStartedPage from "./GettingStartedPage";
import AccessCodePage from "./AccessCodePage";
import ContactUs from './ContactUs';
import CreateEventPage from "./CreateEventPage";
import PasswordReset from "./PasswordReset";
import StorePage from "./Store";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsConditionsPage from "./TermsConditionsPage";

function Application() {
  const user = useContext(UserContext);

  const NotFound = () => (
    <div>Sorry, nothing here.</div>
  )

  return (
        user ?
        <Router>
          <Default path = "/" user={user} default/>
          <Redirect from = "/signUp" to="/welcome" noThrow/>
          <Redirect from = "/signIn" to="/welcome" noThrow/>
          <Redirect from = "/passwordReset" to="/welcome" noThrow />
          <Welcome path = "/welcome" user={user}/>
          <GettingStartedPage path = "/gettingStarted" user={user}/>
          <GettingStartedPage path = "/tutorials" user={user}/>
          <ContactUs path = "/contactUs" user={user}/>
          <AccessCodePage path = "/accessCode" user={user}/>
          <CreateEventPage path = "/newEvent" user={user}/>
          <ProfilePage path="/profile" user={user}/>
          <StorePage path='/invite' user={user}/>
          <PrivacyPolicyPage path='privacy' user={user}/>
          <TermsConditionsPage path='termsandconditions' user={user}/>
        </Router>
      :
        <Router>
          <Default path = "/" default/>
          <Redirect from = "/welcome" to="/signIn" noThrow/>
          <Redirect from = "/profile" to="/signIn" noThrow/>
          <Redirect from = "/newEvent" to="/signUp" noThrow/>
          <Redirect from = "/accessCode" to="/signUp" noThrow/>
          <SignUp path="/signUp" />
          <SignIn path="/signIn" />
          <GettingStartedPage path = "/gettingStarted"/>
          <GettingStartedPage path = "/tutorials"/>
          <ContactUs path = "/contactUs"/>
          <PasswordReset path = "/passwordReset" />
          <StorePage path='/invite' />
          <PrivacyPolicyPage path='privacy'/>
          <TermsConditionsPage path='termsandconditions'/>
          {/* <NotFound default /> */}
        </Router>

  );
}
export default Application;