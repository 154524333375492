import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import App from './App';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBNJ26wzzmVkzhJniXyA-znrv0LH1qSKWU",
//   authDomain: "alaskan-love.firebaseapp.com",
//   databaseURL: "https://alaskan-love.firebaseio.com",
//   projectId: "alaskan-love",
//   storageBucket: "alaskan-love.appspot.com",
//   messagingSenderId: "920138631777",
//   appId: "1:920138631777:web:258b778ab62939d7ff7820",
//   measurementId: "G-VYTSFTHYEE"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
