import React from "react";
import { Link } from "@reach/router";
import { Trans } from "react-i18next";

const Footer = () => {
    return (
        <div className="m pt-10 pb-10 bg-gray-500 text-white fixed bottom-0 w-full">
        <div className="inline-block w-4/12">
          <div className="ml-10"><Trans i18nKey="footerInformation">Information</Trans></div>
          {/* <Link to="/" className="text-gray-500 hover:text-blue-600 ml-20">
            Contact Us
          </Link> */}
          {/* <Link to="/privacy_policy_Special.html" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Terms & Conditions
          </Link>
          <Link to="/terms_and_conditions_Special.html" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Privacy Policy
          </Link> */}
          <a target="_blank" href="/termsandconditions" className="text-gray-400 hover:text-gray-300 ml-4 block">
            <Trans i18nKey="footerTermsCondition">Terms & Conditions</Trans>
          </a>
          <a target="_blank" href="/privacy" className="text-gray-400 hover:text-gray-300 ml-4 block">
            <Trans i18nKey="footerPrivacyPolicy">Privacy Policy</Trans>
          </a>
        </div>
        <div className="inline-block w-4/12">
          <div className="ml-10"><Trans i18nKey="footerDownloadApps">Download the App</Trans></div>
          <a target="_blank" href="https://apps.apple.com/us/app/a-special-day/id1380351953" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Apple
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.codingpengins.app.ptwedding" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Google
          </a>
        </div>
        <div className="inline-block w-4/12">
          <div className="ml-10"><Trans i18nKey="footerFollowUs">Follow Us</Trans></div>
          <a target="_blank" href="https://www.facebook.com/aspecialdayapp" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Facebook
          </a>
          <a target="_blank" href="https://www.twitter.com/@Special64281385" className="text-gray-400 hover:text-gray-300 ml-4 block">
            Twitter
          </a>
        </div>
      </div>
    );
}
export default Footer;