import React, { Component } from "react";
import { navigate } from "@reach/router";
import { Trans } from "react-i18next";
import Footer from "./Footer.js"
import Button from '@material-ui/core/Button';
import {reactLocalStorage} from 'reactjs-localstorage';

import Header from "./Header.js";

class StorePage extends Component {
    accessCode() {
        reactLocalStorage.set('isGuest', true);
        navigate("/signUp");
    }

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
            // Windows Phone must come first because its UA also contains "Android"
          if (/windows phone/i.test(userAgent)) {
              return "Windows Phone";
          }
  
          if (/android/i.test(userAgent)) {
              return "Android";
          }
  
          // iOS detection from: http://stackoverflow.com/a/9039885/177710
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              return "iOS";
          }
  
          return "unknown";
    }

    render() {
        const os = this.getMobileOperatingSystem();
        if (os === 'Android') {
            window.open("https://play.google.com/store/apps/details?id=com.codingpengins.app.ptwedding");
        } else if (os === 'iOS') {
            window.open("https://apps.apple.com/us/app/a-special-day/id1380351953");
        }
        return (
            <div>
                <Header user={this.props.user}/>
                <div className="main-content relative">
                    <div className="w-full h-full top-0">
                        <div className="py-5 text-center bg-yellow-200">
                            <Trans i18nKey="downloadAppRedirect">You will be redirected to the mobile store if on a mobile device. If not click on the store icons below.</Trans>
                        </div>
                        <div className="py-5 w-80 mx-auto text-center">
                            <div className="text-2xl pb-5">
                                <Trans i18nKey="downloadApp">Download the app</Trans>
                            </div>
                            <div className="flex">
                                <div className="inline-block flex-1"><a target="_blank" href="https://apps.apple.com/us/app/a-special-day/id1380351953"><img width="146" src="images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" loading="lazy" alt=""/></a></div>
                                <div className="inline-block flex-1"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.codingpengins.app.ptwedding"><img src="images/Google_Play_Store_badge_EN.svg" loading="lazy" alt=""/></a></div>
                            </div>
                            <div className="text-2xl pb-5 pt-10">
                                <Trans i18nKey="downloadAppWeb">OR Continue via the Web</Trans> 
                            </div>
                            <Button variant="contained" color="primary" onClick={this.accessCode}><Trans i18nKey="signUp">Sign Up</Trans></Button>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        ) 
    }
}
export default StorePage;

