import React, {useState} from "react";
import { Link } from "@reach/router";
import { auth, signInWithApple, signInWithFacebook, signInWithGoogle } from "../firebaseConfig";
import Footer from "./Footer.js";
import { Trans } from "react-i18next";
import Header from "./Header";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const signInWithEmailAndPasswordHandler = 
      (event,email, password) => {
        event.preventDefault();
        if (email.length > 0 && password.length > 0) {
          auth.signInWithEmailAndPassword(email, password).catch(error => {
            setOpen(true);
          });
        }
      };

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;

    if(name === 'userEmail') {
        setEmail(value);
    }
    else if(name === 'userPassword'){
      setPassword(value);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mt-8 pb-40">
      <Header />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><Trans i18nKey="error">Error</Trans></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans i18nKey="errorSignInEmail">Please verify your email and password are correct</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans i18nKey="close">Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <h1 className="text-3xl mb-2 text-center font-bold"><Trans i18nKey="signIn">Sign In</Trans></h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8 mb-10">
        {/* {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>} */}
        <button
          className="bg-red-500 hover:bg-red-600 w-full py-2 text-white"
          onClick = {(event) => {signInWithGoogle()}}>
          <Trans i18nKey="signInGoogle">Sign in with Google</Trans>
        </button>
        <p className="text-center my-3"><Trans i18nKey="or">or</Trans></p>
        <button
          className="bg-blue-500 hover:bg-blue-600 w-full py-2 text-white"
          onClick = {(event) => {signInWithFacebook()}}>
          <Trans i18nKey="signInFacebook">Sign in with Facebook</Trans>
        </button>
        <p className="text-center my-3"><Trans i18nKey="or">or</Trans></p>
        <button
          className="bg-black hover:bg-gray-600 w-full py-2 text-white"
          onClick = {(event) => {signInWithApple()}}>
          <Trans i18nKey="signInApple">Sign in with Apple</Trans>
        </button>
        <p className="text-center my-3"><Trans i18nKey="or">or</Trans></p>
        <form className="">
          <label htmlFor="userEmail" className="block">
            <Trans i18nKey="email">Email</Trans>:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full"
            name="userEmail"
            value = {email}
            placeholder="E.g: example@gmail.com"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            <Trans i18nKey="signInPassword">Password</Trans>:
          </label>
          <input
            type="password"
            className="mt-1 mb-3 p-1 w-full"
            name="userPassword"
            value = {password}
            placeholder="abcd1234"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          <button className="bg-green-400 hover:bg-green-500 w-full py-2 text-white" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            <Trans i18nKey="signInEmail">Sign in via Email</Trans>
          </button>
        </form>
        <p className="text-center my-3">
          <Trans i18nKey="signInNoAccount">Don't have an account?</Trans>{" "}
          <Link to="/signUp" className="text-blue-500 hover:text-blue-600">
            <Trans i18nKey="signInSignUp">Sign up here</Trans>
          </Link>
        </p>
        <p className="text-center my-3">
          <Link to = "/passwordReset" className="text-blue-500 hover:text-blue-600">
            <Trans i18nKey="signInForgotPassword">Forgot Password?</Trans>
          </Link>
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default SignIn;