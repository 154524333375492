import React, { Component } from "react";
import { UserContext } from "../providers/UserProvider";
import { navigate } from "@reach/router";
import { addFamily, addTableForGuest, db, postCommentDb, removeTableForGuest, saveTableLayout, setRSVPAmount, updateEventAddress, updateFamily, updateRegistries, updateStory } from "../firebaseConfig";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Stack from '@material-ui/core/Stack';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CardGiftcard, DeleteForever, Edit, Event, Favorite, Forum, RemoveCircle } from '@material-ui/icons';
import {reactLocalStorage} from 'reactjs-localstorage';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { blue, grey, red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import GridLayout from 'react-grid-layout';

import i18n from '../i18n';
import { Trans } from "react-i18next";

import Footer from "./Footer.js";

import './Pages.scss';
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import { MenuList, MenuItem } from "@material-ui/core";
import Header from "./Header";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getLanguage = () => {
  const lang = i18n.language;
  const langCode = lang.split('-')[0];
  let currentLang = 'en';
  if(Object.keys(i18n.store.data).includes(langCode)) {
    currentLang = langCode;
  }
  return currentLang;
}

const CircularProgressWithLabel = (props) => {
  const language = getLanguage();
  let days = -1;
  let realDays = -1;
  if (props.eventDetails && props.eventDetails.addresses) {
    const firstAddressKeys = Object.keys(props.eventDetails.addresses);
    //get date 
    const firstAddress = props.eventDetails.addresses[firstAddressKeys[0]];
    const date = firstAddress.date;
    if (date > Date.now() / 1000) {
      days = (date - Date.now() / 1000) / 60 / 60 / 24;
    } else if (date - 86400 > Date.now() / 1000) {
      realDays = (date - Date.now() / 1000) / 60 / 60 / 24;
    }
  }
  
  let fillDays = days === -1 ? 0 : days;
  let percentFill = (180 - fillDays) / 180.0 * 100;
  let daysText = `${Math.round(days)} ` + i18n.store.data[language].translation.days;
  if (days === 0 && realDays === 0 ) {
    daysText = <Trans i18nKey="eventToday">Today is the day!</Trans>;
  } else if (days === -1) {
    daysText = <Trans i18nKey="eventPassed">Event has passed.</Trans>;
  } 
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={300} thickness={5} variant="determinate" value={100} className="filled-circle" />
      <CircularProgress size={300} thickness={5} variant="determinate" value={percentFill} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontSize={26} variant="caption" component="div" color="text.secondary">
          {daysText}
        </Typography>
      </Box>
    </Box>
  );
}

class Welcome extends Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);

    const language = getLanguage();

    this.state = {
      adminDeletedUsers: {},
      adminPagination: 1,
      adminUsers: {},
      adminUsersDeleteModal: false,
      adminUserDeleteUserId: '',
      adminUserDeleteUser: {},
      adminUserDeleteEvents: [],
      adminUsersEventModal: false,
      adminUsersGuestsModal: false,
      adminUsersFirst: {},
      adminUsersIdField: '',
      adminUsersLast: {},
      adminUsersNext: true,
      currentTS: Date.now() / 1000,
      downloadGuestsDisabled: false,
      userDetails: null,
      user: null,
      tabValue: null,
      tabSubValue: 'Addresses',
      comments: null,
      eventDetails: null,
      familyCurrentFirst: '',
      familyCurrentLast: '',
      familyError: null,
      familyModalFamilyId: '',
      familyRsvpAmount: 0,
      familyRsvpCompleted: false,
      familyRsvpTotal: 0,
      guests: null,
      inviteCode: '',
      isDragging: false,
      mouseX: 0,
      mouseY: 0,
      needRSVP: false,
      newRegistry: '',
      open: false,
      openAdminUser: '',
      openError: false,
      openErrorSuccessful: false,
      openFamily: false,
      openInviteCode: false,
      page: 0,
      perDays: 90,
      perPage: 50,
      pulledRSVP: false,
      pulledUsers: false,
      rsvpLater: false,
      rsvpReserved: 0,
      rsvpTotal: 0,
      surveyResponses: null,
      showingDetails: false,
      showingStory: false,
      showingSocial: false,
      showingRegistry: false,
      showTableGrid: 0,
      showTableGridList: false,
      showTableGridMouse: false,
      showTableGridRightClick: false,
      showTableGridRightClickX: 0,
      showTableGridRightClickY: 0,
      storyText: '',
      tableDropElement: '',
      tableSize: 10,
      tableType: 'square',
      tableAllowOverlap: false,
      tableGridMemberDrop: {},
      tableGridGuests: [],
      tableGridGuestsValue: [],
      tableGridAttr: {},
      tableGridLoaded: false,
      tablePreventCollision: false,
      totalUsersEstimate: 1300,
      userRsvpAmount: 0,
      comfirmAmountError: false,
      comment: '',
      currentFamilies: [],
      gridLayout: [
        {i: i18n.store.data[language].translation.tableMain, x: 5, y: 0, w: 2, h: 1},
        {i: i18n.store.data[language].translation.tableDanceFloor, x: 5, y: 1, w: 2, h: 2},
      ],
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener("contextmenu", (event) => {
      const {showTableGrid, showTableGridMouse} = this.state;
      if (showTableGridMouse) {
        if (showTableGrid.indexOf("table") === 0 || showTableGrid.indexOf("space") === 0) {
          // show menu
          this.setState({
            showTableGridRightClick: true,
            showTableGridRightClickX: event.clientX,
            showTableGridRightClickY: event.clientY,
          });
        }
        event.preventDefault();
      }
    });
    const user = this.context;
    db.ref(`/users/${user.uid}`).on('value', querySnapShot => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        user,
        userDetails: {...data}
      });
    });
    this.getGuestData();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }

  onMouseMove = (event) => {
    const { clientX, clientY } = event;
    this.setState({
      mouseX: clientX,
      mouseY: clientY,
    });
  }

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    }, () => {
      this.getGuestData();
    })
  };

  handleSubChange = (event, newValue) => {
    this.setState({
      tabSubValue: newValue,
    })
  };

  handleTextChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      userRsvpAmount: newValue,
    });
  }

  handleNewRegistryChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      newRegistry: newValue,
    });
  }

  handleStoryChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      storyText: newValue,
    });
  }

  handleAdminUsersIdChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      adminUsersIdField: newValue,
    });
  }

  handleCommentChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      comment: newValue,
    });
  }

  getGuestData = () => {
    const {tabValue, userDetails} = this.state;
    let eventId = reactLocalStorage.get('eventId');
    let familyId = reactLocalStorage.get('familyId');
    if (!eventId) {
      eventId = tabValue;
    }
    if (userDetails && !familyId) {
      familyId = userDetails[tabValue].familyId;
    }
    if (eventId && familyId) {
      db.ref().child("guests").child(eventId).child(familyId).on('value', snapshot => {
        let data = snapshot.val() ? snapshot.val() : {};
        this.setState({
          pulledRSVP: true,
          needRSVP: data.rsvpCompleted,
          rsvpReserved: data.rsvpReserved,
          rsvpTotal: data.rsvpTotal,
        });
      });
    }
  }

  adminCalls = () => {
    const {adminPagination, adminUsersNext, currentTS, page, perDays, perPage} = this.state;
    //startAfter
    this.setState({
      pulledUsers: true,
    });
    db.ref(`/deletedUsers`).on('value', querySnapShot => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        adminDeletedUsers: {...data},
      });
    });
    if (page === 0) {
      db.ref(`/users`).orderByChild('lastModified').startAfter(currentTS - (perDays * 60 * 60 * 24)).once('value', querySnapShot => {
        let data = [];
        querySnapShot.forEach(child => {
          data.push(child.val());
          data[data.length - 1].id = child.ref.key;
        });
        data = data.reverse();
        this.setState({
          adminUsers: {...data},
          adminPagination: 1,
        });
      });
    } else {
      if (adminUsersNext) {
        db.ref(`/users`).orderByChild('lastModified').startAfter(currentTS - ((perDays * 60 * 60 * 24) * (adminPagination + 1))).endAt(currentTS - ((perDays * 60 * 60 * 24) * adminPagination)).once('value', querySnapShot => {
          let data = [];
          querySnapShot.forEach(child => {
            data.push(child.val());
            data[data.length - 1].id = child.ref.key;
          });
          data = data.reverse();
          this.setState({
            adminPagination: adminPagination + 1,
            adminUsers: {...data},
          });
        });
      } else {
        db.ref(`/users`).orderByChild('lastModified').startAt(currentTS - ((perDays * 60 * 60 * 24) * (adminPagination - 1))).endAt(currentTS - ((perDays * 60 * 60 * 24) * (adminPagination - 2))).once('value', querySnapShot => {
          let data = [];
          querySnapShot.forEach(child => {
            data.push(child.val());
            data[data.length - 1].id = child.ref.key;
          });
          data = data.reverse();
          this.setState({
            adminPagination: adminPagination - 1,
            adminUsers: {...data},
          });
        });
      }
    }
  }

  searchAdminUser = () => {
    const {adminUsersIdField} = this.state;
    if (adminUsersIdField.length === 0) {
      this.adminCalls();
    } else {
      db.ref(`/users`).child(adminUsersIdField).orderByKey().once('value', querySnapShot => {
        let data = querySnapShot.val() ? querySnapShot.val() : {};
        const newData = {};
        newData[adminUsersIdField] = data;
        newData[adminUsersIdField].id = adminUsersIdField;
        this.setState({
          adminUsers: {...newData},
        });
      });
    }
  }

  addRegistry = () => {
    const { eventDetails, newRegistry, tabValue } = this.state;
    const language = getLanguage();
    let regHttpEx = /^(https?|ftp):/;
    let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    if (!regHttpEx.test(newRegistry)) {
      this.setState({
        openErrorSuccessful: false,
        openError: true,
        error: i18n.store.data[language].translation.welcomeErrorRegistryHttp
      });
      return;
    } else if (!regEx.test(newRegistry)) {
      this.setState({
        openErrorSuccessful: false,
        openError: true,
        error: i18n.store.data[language].translation.welcomeErrorRegistryUrl
      });
      return;
    }
    eventDetails.registries.push(newRegistry);
    updateRegistries(tabValue, eventDetails.registries);
    this.setState({
      newRegistry: '',
    });
  }

  removeRegistry = (registry) => {
    const {eventDetails, tabValue} = this.state;
    var index = eventDetails.registries.indexOf(registry);
    if (index !== -1) {
      eventDetails.registries.splice(index, 1);
    }
    updateRegistries(tabValue, eventDetails.registries);
  }

  saveStory = () => {
    const {eventDetails, storyText, tabValue} = this.state;
    const language = getLanguage();
    updateStory(tabValue, storyText, eventDetails.stories.story.img);
    this.setState({
      openError: true,
      openErrorSuccessful: true,
      error: i18n.store.data[language].translation.welcomeStoryUpdate
    });
  }

  getEvent = (event) => {
    db.ref(`/events/${event}`).on('value', querySnapShot => {
      let eventDetails = querySnapShot.val() ? querySnapShot.val() : {};
      if (Object.keys(eventDetails).length > 0) {
        if (eventDetails.stories) {
          this.setState({
            eventDetails,
            storyText: eventDetails.stories.story.text,
          });
        } else {
          this.setState({
            eventDetails,
            storyText: '',
          });
        }
      } else {
        this.setState({
          eventDetails,
          storyText: '',
        });
      }
    });
  }

  getGuests = (event) => {
    db.ref(`/guests/${event}`).on('value', querySnapShot => {
      let guests = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        guests
      });
    });
  }

  getTables = (event) => {
    const { tabValue } = this.state;
    const language = getLanguage();
    this.setState({
      tableGridLoaded: false,
    });
    db.ref(`/guests/${event}`).on('value', querySnapShot => {
      let guests = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        guests
      });
    });
    db.ref(`/tables/${tabValue}`).on('value', querySnapShot => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      let updatedLayout = [
        {i: i18n.store.data[language].translation.tableMain, x: 5, y: 0, w: 2, h: 1},
        {i: i18n.store.data[language].translation.tableDanceFloor, x: 5, y: 1, w: 2, h: 2},
      ];
      let tableGridAttr = {
        type: 'square',
        size: 10,
      }
      let tableGridGuests = [];
      let tableGridGuestsValue = [];
      if (data) {
        let dataKeys = Object.keys(data);
        dataKeys.forEach(key => {
          if (key === 'layout') {
            updatedLayout = data.layout;
          } else if (key === 'attr') {
            tableGridAttr = data.attr;
            if (!data.attr.type) {
              tableGridAttr.type = 'square'
            }
            if (!data.attr.size) {
              tableGridAttr.size = 10;
            }
          } else {
            tableGridGuests[key] = Object.keys(data[key]).length; 
            tableGridGuestsValue[key] = data[key];
          }
        });
        if (Object.keys(updatedLayout).length -2 !== tableGridGuests.length - 1) {
          Object.keys(updatedLayout).forEach(key => {
            if(updatedLayout[key].i.indexOf("table") === 0) {
              const tableNum = updatedLayout[key].i.replace(/[^0-9]/g, '');
              if (!(tableNum in tableGridGuests)) {
                tableGridGuests[tableNum] = 0;
              }
            }
          });
        }
      }
      this.setState({
        gridLayout: updatedLayout,
        tableGridAttr: tableGridAttr,
        tableGridGuests: tableGridGuests,
        tableGridGuestsValue: tableGridGuestsValue,
        tableGridLoaded: true,
        tableSize: tableGridAttr.size,
        tableType: tableGridAttr.type,
      });
    });
  }

  getComments = (event) => {
    db.ref(`/comments/${event}`).on('value', querySnapShot => {
      let comments = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        comments
      });
    });
  }

  getSurveyResponses = (event) => {
    const { guests } = this.state;
    if (guests == null) {
      this.getGuests(event);
    }
    db.ref(`/surveyResponses/${event}`).on('value', querySnapShot => {
      let surveyResponses = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        surveyResponses
      });
    });
  }

  familyList = (id, family) => {
    let rsvpCompleted = false;
    let rsvpReserved = -1;
    let rsvpTotal = -1;
    let familyKeys = Object.keys(family);
    let familyMembers = [];
    familyKeys.forEach(key => {
      if (key === 'rsvpCompleted') {
        rsvpCompleted = family[key];
      } else if (key === 'rsvpReserved') {
        rsvpReserved = family[key];
      } else if (key === 'rsvpTotal') {
        rsvpTotal = family[key];
      } else {
        // it is a family member obj
        let familyMemberKeys = Object.keys(family[key]);
        let firstName = null;
        let lastName = null;
        let hasAccount = false;
        let table = null;
        familyMemberKeys.forEach(memberKey => {
          if (memberKey === 'firstName') {
            firstName = family[key][memberKey];
          } else if (memberKey === 'lastName') {
            lastName = family[key][memberKey];
          } else if (memberKey === 'hasAccount') {
            hasAccount = family[key][memberKey];
          } else if (memberKey === 'hasAccount') {
            hasAccount = family[key][memberKey];
          } else if (memberKey === 'table') {
            table = family[key][memberKey];
          } 
        });
        familyMembers.push({
          firstName,
          lastName,
          hasAccount,
          id: key,
          table,
        });
      }
    });
    return {
      rsvpCompleted,
      rsvpReserved,
      rsvpTotal,
      familyMembers,
      id,
    }
  }

  onDragStart = (familyId, member) => {
    member.familyId = familyId;
    // event.dataTransfer.setData("text/plain", "");
    this.setState({
      tableAllowOverlap: true,
      tablePreventCollision: true,
      isDragging: true,
      tableGridMemberDrop: member,
    });
  }

  onDragEnd = (event) => {
    this.setState({
      tableAllowOverlap: false,
      tablePreventCollision: false,
      isDragging: false,
      tableGridMemberDrop: {},
    });
  }

  onDragStartElement = (element) => {
    this.setState({
      tableDropElement: element,
      isDragging: true,
    });
  }

  onDragEndElement = (element) => {
    this.setState({
      tableDropElement: '',
      isDragging: false,
    });
  }

  onDragStartGrid = () => {
    this.setState({
      isDragging: true,
    });
  }

  onDragEndGrid = () => {
    this.setState({
      isDragging: false,
    });
  }

  clearSub() {
    this.setState({
      showingDetails: false,
      showingRegistry: false,
      showingSocial: false,
      showingStory: false,
    });
  }

  showEventDetails(event) {
    this.setState({ showingDetails: true });
  }

  showRegistry(event) {
    this.setState({ showingRegistry: true });
  }

  showSocial(event) {
    this.getComments(event);
    this.setState({ showingSocial: true });
  }

  showStory(event) {
    this.setState({ showingStory: true });
  }

  removeTable = (familyId, guestId, tableNum) => {
    const { tabValue } = this.state;
    removeTableForGuest(tabValue, familyId, guestId, tableNum);
  }

  cancelEditAddress = () => {
    this.setState({
      editingAddress: null,
    });
  }

  saveAddress = (currentEvent) => {
    const {editingAddress, venueName, venueAddress} = this.state;
    const language = getLanguage();
    // paul check the address if filled and is valid via google (then show best guess)
    fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBii9DkRCProTurJ7zJMLSDkYh-mq2UCdk&address=" + venueAddress)
    .then(res => res.json())
    .then(
        (result) => {
            if (result.results.length === 0) {
              this.setState({
                openError: true,
                openErrorSuccessful: false,
                error: i18n.store.data[language].translation.welcomeErrorAddressNoMatch
              });
              return;
            } else if (result.results.length === 1) {
                const address = result.results[0];
                const lat = address.geometry.location.lat;
                const lng = address.geometry.location.lng;
                const addressComponents = address.address_components;
                let street = '';
                let city = '';
                let zip = '';
                let country = '';
                let state = '';
                addressComponents.forEach(component => {
                    const types = component.types;
                    types.forEach(type => {
                        if (type === 'country') {
                            country = component.long_name;
                        } else if (type === 'postal_code') {
                            zip = component.long_name;
                        } else if (type === 'street_number') {
                            if (street.length > 0 ) {
                                street = component.long_name + ' ' + street;
                            } else {
                                street = component.long_name;
                            }
                        } else if (type === 'route') {
                            if (street.length > 0 ) {
                                street = street + ' ' + component.long_name;
                            } else {
                                street = component.long_name;
                            }
                        } else if (type === 'locality') {
                            city = component.long_name;
                        } else if (type === 'administrative_area_level_1') {
                            state = component.long_name;
                        } 
                    });
                });
                // create linux timestamp from time
                const event = {
                    lat,
                    lng,
                    street,
                    city,
                    zip,
                    country,
                    state,
                    name: venueName,
                }
                updateEventAddress(currentEvent, editingAddress, event);
                this.cancelEditAddress();
                return;
            } else {
                // multiple events do popup with select
                this.setState({
                  openError: true,
                  openErrorSuccessful: false,
                  error: i18n.store.data[language].translation.welcomeErrorAddressNoMatch
                });
                return;
            }
        },
        (error) => {
          this.setState({
            openError: true,
            openErrorSuccessful: false,
            error: i18n.store.data[language].translation.somethingWrong
          });
        }
    );
  }

  editAddress = (address) => {
    this.setState({
      editingAddress: address.id,
      venueName: address.locationName,
      venueAddress: address.street + ' ' + address.city + ' ' + address.state + ' ' + address.country,
    });
  }

  editFamily = (familyId) => {
    const { guests } = this.state;
    const currentFamilies = [];
    let familyRsvpCompleted = false;
    let familyRsvpAmount = 0;
    let familyRsvpTotal = 0;
    Object.keys(guests).forEach(fID => {
      if (fID === familyId) {
        const family = guests[fID];
        let familyKeys = Object.keys(family);
        familyKeys.forEach(key => {
          if (key === 'rsvpCompleted' || key === 'rsvpReserved' || key === 'rsvpTotal') {
            // do nothing
          } else {
            const member = family[key];
            currentFamilies.push({firstName: member.firstName, lastName: member.lastName, id: key});
          }
        });
        familyRsvpCompleted = family.rsvpCompleted ? family.rsvpCompleted : false;
        familyRsvpAmount = family.rsvpReserved;
        familyRsvpTotal = family.rsvpTotal;
      }
    });
    this.setState({
      openFamily: true,
      familyModalFamilyId: familyId,
      currentFamilies,
      familyRsvpCompleted,
      familyRsvpAmount,
      familyRsvpTotal,
    });
  }

  guestList(hideEdit=false) {
    const { guests } = this.state;
    if (guests !== null) {
      return (Object.keys(guests).map((guestKey) => {
        const family = guests[guestKey];
        const familyDetails = this.familyList(guestKey, family);
        return (
          <div className="family-block pb-3 pl-3 pt-3">
            <div><Trans i18nKey="rsvpCompleted">RSVP Completed</Trans>:{familyDetails.rsvpCompleted ? <Trans i18nKey="yes">yes</Trans> : <Trans i18nKey="no">no</Trans>}</div>
            <div><Trans i18nKey="rsvpReserved">RSVP Reserved</Trans>:{familyDetails.rsvpReserved}</div>
            <div><Trans i18nKey="rsvpTotal">RSVP Total</Trans>:{familyDetails.rsvpTotal}</div>
            {familyDetails.familyMembers.map((familyMember) => 
              <div>
                <div><Trans i18nKey="firstName">First Name</Trans>: {familyMember.firstName}</div>
                <div><Trans i18nKey="lastName">Last Name</Trans>: {familyMember.lastName}</div>
                <div><Trans i18nKey="hasAccount">Has Account</Trans>: {familyMember.hasAccount ? <Trans i18nKey="yes">yes</Trans> : <Trans i18nKey="no">no</Trans>}</div>
              </div>
            )}
            {!hideEdit && (<div className="icon-white">
              <Edit style={{ fontSize: 24, cursor: 'pointer', color: grey[500] }} onClick={() => this.editFamily(guestKey)}/>
            </div>)}
          </div>
        );
      }));
    }
  }

  guestListMap() {
    const { guests } = this.state;
    if (guests !== null) {
      return (Object.keys(guests).map((guestKey) => {
        const family = guests[guestKey];
        const familyDetails = this.familyList(guestKey, family);
        return (
          <div className="table-family-block">
            {familyDetails.familyMembers.map((familyMember) => 
              <div>
                <div className={familyMember.table ? "table-member-block" : "table-member-block-need-table"} draggable={familyMember.table ? false : true} onDragStart={() => this.onDragStart(guestKey, familyMember)} onDragEnd={this.onDragEnd}>        
                  {familyMember.firstName} {familyMember.lastName} : {familyMember.table ? familyMember.table : '-'}
                  {familyMember.table && (<RemoveCircle style={{ fontSize: 24, cursor: 'pointer', color: red[500] }} onClick={() => this.removeTable(guestKey, familyMember.id, familyMember.table)}/>)}
                </div>
              </div>
            )}
          </div>
        );
      }));
    }
  }

  handleChangePage = (event, newPage) => {
    const {page} = this.state;
    if (newPage > page) {
      this.setState({page: newPage, adminUsersNext: true}, () => this.adminCalls());
    } else {
      this.setState({page: newPage, adminUsersNext: false}, () => this.adminCalls());
    }
  };

  adminUserEvent = (eventId) => {
    this.getEvent(eventId);
    this.setState({
      adminUsersEventModal: true,
    });
  }

  adminUserGuests = (eventId) => {
    this.getGuests(eventId);
    this.setState({
      adminUsersGuestsModal: true,
    });
  }

  handleUserAdminsEventClose = () => {
    this.setState({
      adminUsersEventModal: false,
    });
  }

  handleUserAdminsGuestsClose = () => {
    this.setState({
      adminUsersGuestsModal: false,
    });
  }

  deleteAdminUser = (userId, user, events) => {
    if (userId.length > 0) {
      this.setState({
        adminUsersDeleteModal: true,
        adminUserDeleteUserId: userId,
        adminUserDeleteUser: user,
        adminUserDeleteEvents: events,
      });
    }
  }

  handleUserAdminsDeleteClose = () => {
    this.setState({
      adminUsersDeleteModal: false,
      adminUserDeleteUserId: '',
      adminUserDeleteUser: {},
      adminUserDeleteEvents: [],
    });
  }

  handleUserAdminsDeleteConfirm = () => {
    const {adminUserDeleteUserId, adminUserDeleteUser, adminUserDeleteEvents} = this.state;
    const language = getLanguage();
    db.ref(`/deletedUsers/${adminUserDeleteUserId}`).update({
      deleteEvents: adminUserDeleteEvents,
      deleteUser: adminUserDeleteUser,
    });
    if (adminUserDeleteUserId.length > 0) {
      db.ref('/users/').child(adminUserDeleteUserId).remove();
    }
    adminUserDeleteEvents.forEach(event => {
      if (event.isAdmin) {
        if (event.id && event.id.length > 0) {
          db.ref('/events/').child(event.id).remove();
          db.ref('/guests/').child(event.id).remove();
          db.ref('/comments/').child(event.id).remove();
          db.ref('/tables/').child(event.id).remove();
        }
      }
    });
    if (adminUserDeleteUserId.length > 0) {
      this.setState({
        adminUsersDeleteModal: false,
        adminUserDeleteUserId: '',
        adminUserDeleteUser: {},
        adminUserDeleteEvents: [],
        openError: true,
        openErrorSuccessful: true,
        error: i18n.store.data[language].translation.welcomeDeleteUser
      });
    }
  }

  showUserAdminsDelete = () => {
    const {adminUserDeleteUser, adminUserDeleteEvents, adminUsersDeleteModal} = this.state;
    if (adminUsersDeleteModal) {
      let adminEvents = [];
      adminUserDeleteEvents.forEach((event) => {
        if (event.isAdmin) {
          adminEvents.push(event);
        }
      });
      return (
        <div>
          Deleting the user: {`${adminUserDeleteUser.firstName} ${adminUserDeleteUser.lastName}`}
          {adminEvents.map((event) => {
            return <div>Also deleting this event: {event.id}</div>
          })}
        </div>
      )
    }
  }

  adminDeletedUsers() {
    const {adminDeletedUsers} = this.state;
    if (adminDeletedUsers && Object.keys(adminDeletedUsers).length > 0) {
      return (
        <div>
          <div className="text-l mb-2 text-center font-bold">Pending Deleted Users Accounts</div>
          {(Object.keys(adminDeletedUsers).map((key, i) => (
            <div className="pl-5">
              {(i + 1) +" : " + adminDeletedUsers[key].deleteUser.firstName+ " " + adminDeletedUsers[key].deleteUser.lastName + " " + key}
            </div>
          )))}
        </div>
      )
    }
  }

  adminUserList() {
    const { adminUsers, openAdminUser, page, perPage, totalUsersEstimate } = this.state;
    if (adminUsers !== null) {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Locale</TableCell>
                <TableCell align="right">Is an Admin</TableCell>
                <TableCell align="right">Last Access</TableCell>
                <TableCell align="right">Delete?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(Object.keys(adminUsers).map((userKey) => {
                  const user = adminUsers[userKey];
                  const attrKeys = Object.keys(user);
                  let id = '';
                  let email = '';
                  let firstName = '';
                  let lastName = '';
                  let locale = '';
                  let isAdmin = false;
                  let lastModified = '';
                  let events = [];
                  attrKeys.forEach(attrKey => {
                    if (typeof user[attrKey] === "object") {
                      user[attrKey].id = attrKey;
                      events.push(user[attrKey]);
                      if (user[attrKey].isAdmin) {
                        isAdmin = true;
                      }
                    } else {
                      if (attrKey === 'id') {
                        id = user[attrKey];
                      } else if (attrKey === 'firstName') {
                        firstName = user[attrKey];
                      } else if (attrKey === 'lastName') {
                        lastName = user[attrKey];
                      } else if (attrKey === 'locale') {
                        locale = user[attrKey];
                      } else if (attrKey === 'email') {
                        email = user[attrKey];
                      } else if (attrKey === 'lastModified') {
                        lastModified = user[attrKey];
                      }
                    }
                  });
                  return (
                    <React.Fragment>
                      <TableRow key={userKey}>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small" onClick={() => 
                            {( openAdminUser === userKey ? this.setState({openAdminUser: ''}) : this.setState({ openAdminUser: userKey}) )}}
                          >
                          {openAdminUser === userKey ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {id}
                        </TableCell>
                        <TableCell align="right">{email}</TableCell>
                        <TableCell align="right">{firstName}</TableCell>
                        <TableCell align="right">{lastName}</TableCell>
                        <TableCell align="right">{locale}</TableCell>
                        <TableCell align="right">{isAdmin ? 'yes' : '-'}</TableCell>
                        <TableCell align="right">{lastModified && new Date(lastModified * 1000).toISOString().split(".")[0]}</TableCell>
                        <TableCell align="right">
                          <DeleteForever style={{ fontSize: 32, cursor: 'pointer' }} color="primary" onClick={() => this.deleteAdminUser(userKey, user, events)}/>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={openAdminUser === userKey} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                 <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Is Admin</TableCell>
                                    {/* <TableCell align="right">RSVP Completed</TableCell>
                                    <TableCell align="right">RSVP Reserved</TableCell>
                                    <TableCell align="right">RSVP Total</TableCell> */}
                                    <TableCell align="right">Guest List</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {events.map((event) => (
                                    <TableRow key={event.id}>
                                      <TableCell style={{ cursor: 'pointer', color: blue[500] }} component="th" scope="row" onClick={() => this.adminUserEvent(event.id)}>
                                        {event.id}
                                      </TableCell>
                                      <TableCell align="right">{(event.isAdmin ? <Trans i18nKey="yes">yes</Trans> : '-')}</TableCell>
                                      {/* <TableCell align="right">{(event.rsvpCompleted ? <Trans i18nKey="yes">yes</Trans> : <Trans i18nKey="no">no</Trans> )}</TableCell>
                                      <TableCell align="right">{(event.rsvpReserved ? event.rsvpReserved : '-')}</TableCell>
                                      <TableCell align="right">{(event.rsvpTotal ? event.rsvpTotal : '-')}</TableCell> */}
                                      <TableCell align="right" style={{ cursor: 'pointer', color: blue[500] }} component="th" scope="row" onClick={() => this.adminUserGuests(event.id)}>
                                        {event.id}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
              }))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[perPage]}
                  colSpan={8}
                  count={totalUsersEstimate}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )
    }
  }

  onMouseOver = (item) => {
    if (item.indexOf("table") === 0) {
      const tableNum = item.replace(/[^0-9]/g, '');
      const {tableGridGuestsValue} = this.state;
      if (tableNum.length > 0 && tableGridGuestsValue[tableNum]) {
        this.setState({
          showTableGridMouse: true,
          showTableGridList: true,
          showTableGrid: tableNum,
        });
        return;
      }
    }
    this.setState({
      showTableGridMouse: true,
      showTableGrid: item,
    });
  }
  
  onMouseOut = () => {
    this.setState({
      showTableGridList: false,
      showTableGridMouse: false,
    });
  }

  handleTableDelete = () => {
    const {gridLayout, showTableGrid, tabValue} = this.state;
    console.log("deleting");
    console.log(showTableGrid);
    console.log(gridLayout);
    const layout = [];
    gridLayout.forEach(item => {
      if(item.i !== showTableGrid) {
        layout.push(item);
      }
    });
    saveTableLayout(tabValue, layout);
    this.setState({
      gridLayout: layout,
    });
    this.setState({showTableGridRightClick: false});
  }

  handleTableRename = () => {
    this.setState({showTableGridRightClick: false});
  }

  handleTableClose = () => {
    this.setState({showTableGridRightClick: false});
  }

  tableRightClickMenu = () => {
    return <MenuList>
      <MenuItem onClick={this.handleTableDelete}><Trans i18nKey="delete">Delete</Trans></MenuItem>
      {/* <MenuItem onClick={this.handleTableRename}><Trans i18nKey="rename">Rename</Trans></MenuItem> */}
      <MenuItem onClick={this.handleTableClose}><Trans i18nKey="close">Close</Trans></MenuItem>
    </MenuList>
  }

  tableGuestList = () => {
    const {showTableGrid, tableGridGuestsValue} = this.state;
    if (showTableGrid > 0 && tableGridGuestsValue[showTableGrid]) {
      return (Object.keys(tableGridGuestsValue[showTableGrid]).map((member) => (
        <div>
          <div>{`${tableGridGuestsValue[showTableGrid][member].firstName} ${tableGridGuestsValue[showTableGrid][member].lastName}`}</div>
        </div>
      )));
    }
    return;
  }

  comments() {
    const { comments } = this.state;
    if (comments !== null) {
      return (Object.keys(comments).map((commentKey) => {
        const comment = comments[commentKey];
        if (comment.type === 'image') {
          return (
            <div className="comment-block">
              <img src={comment.imageUrl} className="comment-img"/>
              {comment.comment.length > 0 && (
                <div className="inline-block">{comment.first_name} {comment.last_name} <Trans i18nKey="said">said</Trans>:</div>
              )}
              {comment.comment.length === 0 && (
                <div className="inline-block"><Trans i18nKey="postedBy">Posted by</Trans>: {comment.first_name} {comment.last_name}</div>
              )}
              <div className="inline-block">{comment.comment}</div>
            </div>
          );
        } else {
          if (comment.comment.length > 0) {
            return (
              <div className="comment-block">
                <div className="inline-block">{comment.first_name} {comment.last_name} <Trans i18nKey="said">said</Trans>:</div>
                <div className="inline-block">{comment.comment}</div>
              </div>
            );
          }
        }
      }));
    } else {
      return (<div><Trans i18nKey="commentsNone">No comments yet</Trans></div>)
    }
  }

  surveyResponses() {
    const { guests, surveyResponses } = this.state;
    if (surveyResponses !== null && guests !== null) {
      return (Object.keys(surveyResponses).map((surveyResponseKey) => {
        const guestObj = surveyResponses[surveyResponseKey];
        return (Object.keys(guestObj).map((guestKey) => {
          var name = '';
          const familyObjKeys = Object.keys(guests);
          familyObjKeys.forEach(familyKey => {
            const family = guests[familyKey];
            const guestsObjKeys = Object.keys(family);
            guestsObjKeys.forEach(familyMemberKey => {
              if (familyMemberKey === guestKey) {
                const familyMember = family[familyMemberKey];
                name = familyMember['firstName'] + " " + familyMember['lastName'];
              }
            });
          });
          const survey = guestObj[guestKey];
          return (Object.keys(survey).map((questionsKey) => {
            const questions = survey[questionsKey];
            var questionsReturn = [];
            (Object.keys(questions).map((questionKey) => {
              const question = questions[questionKey];
              var response = (
                <strong>{question["value"]}</strong>
              );
              if (question["type"] === 'file') {
                response = <a className="text-blue-500 hover:text-blue-600" href={question["image"]} target="_blank"><Trans i18nKey="image">Image</Trans></a>;
              }
              const questionDetails = (
                <div><i><Trans i18nKey="question">Question</Trans>:</i> {question["text"]} ::: {response}</div>                
              );
              questionsReturn.push(
                <div>{questionDetails}</div>
              );
            }));
            return (
              <div className='event-block'>
                <div>{name}</div>
                <div>{questionsReturn}</div>
              </div>
            );
          }));
        }));
      }));
    }
  }

  addressesList(event, addresses) {
    const {editingAddress, venueAddress, venueName} = this.state;
    let venues = [];
    const addressesKeys = Object.keys(addresses);
    addressesKeys.forEach(key => {
      if (addresses[key]['type'] === null || addresses[key]['type'] !== 'hotel') {
        venues.push({
          id: key,
          city: addresses[key]['city'],
          country: addresses[key]['country'],
          date: addresses[key]['date'],
          formattedDate: new Date(addresses[key]['date'] * 1000),
          latitude: addresses[key]['latitude'],
          locationName: addresses[key]['locationName'],
          longitude: addresses[key]['longitude'],
          name: addresses[key]['name'],
          state: addresses[key]['state'],
          street: addresses[key]['street'],
          timezone: addresses[key]['timezone'],
          uber: addresses[key]['uber'],
        });
      }
    });
    return venues.map(venue => (
      <div className='event-block'>
        {venue.id !== editingAddress && (
          <>
            <div>{venue.locationName}</div>
            <div><Trans i18nKey="date">Date</Trans>: {venue.formattedDate.getMonth()+1}/{venue.formattedDate.getDate()}/{venue.formattedDate.getFullYear()} {venue.formattedDate.getHours()}:{(venue.formattedDate.getMinutes() < 10 ? '0' + venue.formattedDate.getMinutes(): venue.formattedDate.getMinutes())}</div>
            <div>{venue.street}</div>
            <div>{venue.city} {venue.state}, {venue.country}</div>
            <div className="icon-white">
              <Edit style={{ fontSize: 24, cursor: 'pointer', color: grey[500] }} onClick={() => this.editAddress(venue)}/>
            </div>
          </>
        )}
        {venue.id === editingAddress && (
          <>
            <Stack spacing={2} direction="row" className="mb-4">
              <TextField
                sx={{ width: 500 }}
                name="venueName"
                label="Venue Name"
                value={venueName}
                onChange={this.onChangeHandler}
              />
            </Stack>
            <Stack spacing={2} direction="row" className="mb-4">
              <TextField
                sx={{ width: 500 }}
                multiline
                rows={3}
                name="venueAddress"
                label="Location of event (address with city and state)"
                value={venueAddress}
                onChange={this.onChangeHandler}
              />
            </Stack>
            <div className="mt-3">
              <Button variant={"contained"} onClick={() => this.saveAddress(event, venue)} color="primary"><Trans i18nKey="save">Save</Trans></Button>
              <Button onClick={this.cancelEditAddress} color="primary">
                <Trans i18nKey="cancel">Cancel</Trans>
              </Button>
            </div>
          </>
        )}
      </div>
    ));
  }

  registriesList(isAdmin, registries) {
    if (isAdmin) {
      return registries.map(registry => (
        <div>
          <a href={registry} target="_blank">{registry}</a> <RemoveCircle style={{ fontSize: 24, cursor: 'pointer', color: red[500] }} onClick={() => this.removeRegistry(registry)}/>
        </div>
      ));
    } else {
      return registries.map(registry => (
        <div>
          <a href={registry} target="_blank">{registry}</a>/>
        </div>
      ));
    }
  }

  shareInvite = (code) => {
    this.setState({
      inviteCode: code,
      openInviteCode: true,
    });
  }

  handleInviteCodeClose = () => {
    this.setState({
      openInviteCode: false,
    });
  }

  postComment = () => {
    const { comment, tabValue, userDetails } = this.state;
    const language = getLanguage();
    if (comment.length === 0) {
      this.setState({
        openError: true,
        openErrorSuccessful: false,
        error: i18n.store.data[language].translation.welcomeErrorCommentEmpty
      });
      return;
    }
    const user = this.context;
    postCommentDb(user, userDetails, tabValue, comment);
    this.setState({
      comment: '',
      openError: true,
      openErrorSuccessful: true,
      error: i18n.store.data[language].translation.welcomeCommentSuccessful
    });
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      rsvpLater: true,
    });
  };

  handleErrorClose = () => {
    this.setState({
      openError: false,
    });
  }

  comfirmAmount = () => {
    const { userRsvpAmount, rsvpTotal } = this.state;
    if (userRsvpAmount > rsvpTotal) {
      // throw error
      this.setState({
        comfirmAmountError: true,
      });
      return;
    }
    // do rsvp update
    setRSVPAmount(userRsvpAmount);
    this.setState({
      open: false,
      needRSVP: true,
      rsvpLater: false,
    });
  }

  capitalizeTxt(txt) {
    if (txt && txt.length > 0) {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }
    return txt;
  }

  addFamilyModal = () => {
    this.setState({
      openFamily: true,
    });
  }

  downloadGuests = () => {
    const language = getLanguage();
    this.setState({
      downloadGuestsDisabled: true,
    });
    const {guests} = this.state;
    let guestListArr = [{firstName: i18n.store.data[language].translation.firstName, lastName: i18n.store.data[language].translation.lastName, tableNumber: i18n.store.data[language].translation.tableNumber}];
    Object.keys(guests).map((guestKey) => {
      const family = guests[guestKey];
      const familyDetails = this.familyList(guestKey, family);
      familyDetails.familyMembers.forEach(member => {
        guestListArr.push({
          firstName: member.firstName,
          lastName: member.lastName,
          table: member.table,
        });
      });
    });
    window.open(`data:text/csv;charset=utf-8,${guestListArr.reduce((acc, curr) => (`${acc}${Object.values(curr).join(",")}\n`), "")}`);
    this.setState({
      downloadGuestsDisabled: false,
    });
  }

  handleFamilyClose = () => {
    this.setState({
      openFamily: false,
    });
  }

  familyDone = () => {
    this.familyAddAnother(false);
  }

  familyAddAnother = (dialog = true) => {
    //need to save and clear items
    const {currentFamilies, familyCurrentFirst, familyCurrentLast, familyModalFamilyId, familyRsvpCompleted, familyRsvpAmount, familyRsvpTotal, tabValue} = this.state;
    if ((familyCurrentFirst.trim().length === 0 || familyCurrentLast.trim().length === 0) && !(familyCurrentFirst.trim().length === 0 && familyCurrentLast.trim().length === 0)) {
      this.setState({
        familyError: <Trans i18nKey="addMemberError">First and last name must be filled</Trans>,
      });
      return;
    }

    let rsvpAmount = familyRsvpAmount;
    if (familyCurrentFirst.trim().length !== 0 && familyCurrentLast.trim().length !== 0) {
      currentFamilies.push({firstName: familyCurrentFirst, lastName: familyCurrentLast});
      if(currentFamilies.length > familyRsvpAmount) {
        rsvpAmount = currentFamilies.length;
      }
    }

    //need to check if it is an update
    if (familyModalFamilyId.length === 0) {
      // need to save
      addFamily(tabValue, currentFamilies, rsvpAmount, familyRsvpTotal, familyRsvpCompleted);
    } else {
      updateFamily(tabValue, familyModalFamilyId, currentFamilies, rsvpAmount, familyRsvpTotal, familyRsvpCompleted);
    }
    // clear out items on modal
    this.setState({
      currentFamilies: [],
      familyCurrentFirst: '',
      familyCurrentLast: '',
      familyModalFamilyId: '',
      familyRsvpAmount: null,
      familyRsvpTotal: 0,
      familyRsvpCompleted: false,
      openFamily: dialog,
    });
  }

  handleFirstNameChange = (event) => {
    const {value} = event.currentTarget;
    this.setState({
      familyCurrentFirst: value,
    });
  }

  handleLastNameChange = (event) => {
    const {value} = event.currentTarget;
    this.setState({
      familyCurrentLast: value,
    });
  }

  addMember = () => {
    const {familyCurrentFirst, familyCurrentLast, currentFamilies, familyRsvpAmount} = this.state;
    if (familyCurrentFirst.trim().length === 0 || familyCurrentLast.trim().length === 0) {
      this.setState({
        familyError: <Trans i18nKey="addMemberError">First and last name must be filled</Trans>,
      });
      return;
    }

    currentFamilies.push({firstName: familyCurrentFirst, lastName: familyCurrentLast});
    let rsvpAmount = familyRsvpAmount;
    if(currentFamilies.length > familyRsvpAmount) {
      rsvpAmount = currentFamilies.length;
    }
    this.setState({
      familyCurrentFirst: '',
      familyCurrentLast: '',
      familyError: '',
      currentFamilies,
      familyRsvpAmount: rsvpAmount,
    })
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'comment') {
      this.setState({
        comment: value,
      });
    } else if(name === 'venueName') {
      this.setState({
        venueName: value,
      });
    } else if(name === 'venueAddress') {
      this.setState({
        venueAddress: value,
      });
    }
  };

  deleteUser = (member) => {
    const { currentFamilies } = this.state;
    let count = 0;
    let found = false;
    if (member.id && member.id.length > 0) {
      currentFamilies.forEach(cMember => {
        if (!found && cMember.id === member.id ) {
          currentFamilies.splice(count, 1);
          found = true;
        }
        count++;
      });
    } else {
      currentFamilies.forEach(cMember => {
        if (!found && cMember.firstName === member.firstName && cMember.lastName === member.lastName) {
          currentFamilies.splice(count, 1);
          found = true;
        }
        count++;
      });
    }
    this.setState({
      currentFamilies,
    });
  }

  showUserAdminsEvent = () => {
    const {eventDetails} = this.state;

    if(eventDetails) {
      return (Object.keys(eventDetails).map((eventDetailKey) => {
        const eventDetail = eventDetails[eventDetailKey];
        if (typeof eventDetail === "object") {
          return (Object.keys(eventDetail).map((detailKey) => {
            const detail = eventDetail[detailKey];
            let arrayKeys = [];
            Object.keys(detail).forEach((key) => {
              arrayKeys.push(
                <div>{key}: {( key === 'date' ? (new Date(detail[key] * 1000).toString()) : detail[key])}</div>
              )
            });
            return (
              <div>
                <h4>{eventDetailKey}</h4>
                <div>{arrayKeys}</div>
                <hr></hr>
              </div>
            )
            // return (
            //   <div>{detailKey}: {detail}</div>
            // )
          }));
        } else {
          return (
            <div>{eventDetailKey}: {eventDetail}</div>
          )
        }
        // return (
        //   <div className="family-block pb-3 pl-3 pt-3">
        //     <div>RSVP Completed:{familyDetails.rsvpCompleted ? 'Yes' : 'No'}</div>
        //     <div>RSVP Reserved:{familyDetails.rsvpReserved}</div>
        //     <div>RSVP Total:{familyDetails.rsvpTotal}</div>
        //     {familyDetails.familyMembers.map((familyMember) => 
        //       <div>
        //         <div>First Name: {familyMember.firstName}</div>
        //         <div>Last Name: {familyMember.lastName}</div>
        //         <div>Has Account: {familyMember.hasAccount ? 'Yes' : 'No'}</div>
        //       </div>
        //     )}
        //     <div className="icon-white">
        //       <Edit style={{ fontSize: 24, cursor: 'pointer', color: grey[500] }} onClick={() => this.editFamily(guestKey)}/>
        //     </div>
        //   </div>
        // );
      }));
    }
  }

  showCurrentFamilies = () => {
    const { currentFamilies } = this.state;
    // made readOnly so they cant change the name later
    return currentFamilies.map(family => (
      <div>
        <TextField
          margin="dense"
          id="name"
          label={<Trans i18nKey="firstName">First Name</Trans>}
          value={family.firstName}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          id="name"
          label={<Trans i18nKey="lastName">Last Name</Trans>}
          value={family.lastName}
          InputProps={{
            readOnly: true,
          }}
        />
        <Button onClick={() => this.deleteUser(family)} color="primary">
          <Trans i18nKey="delete">Delete</Trans>
        </Button>
      </div>
    ))
  }

  handleRsvpCompleted = () => {
    const {familyRsvpCompleted} = this.state;
    this.setState({
      familyRsvpCompleted: !familyRsvpCompleted
    });
  }
  
  handleRsvpAmount = (event) => {
    const {value} = event.currentTarget;
    this.setState({
      familyRsvpAmount: value,
    });
  }

  handleRsvpTotal = (event) => {
    const {value} = event.currentTarget;
    this.setState({
      familyRsvpTotal: value,
    });
  }

  handleTableChange = (event) => {
    const {tabValue} = this.state;
    const {value} = event.currentTarget;
    this.setState({
      tableSize: value,
    });
    db.ref(`/tables/${tabValue}/attr`).update({
      size: value,
    });
  }

  tableTypeSquare = () => {
    const {tabValue} = this.state;
    this.setState({
      tableType: 'square',
    });
    // update ref
    db.ref(`/tables/${tabValue}/attr`).update({
      type: 'square',
    });
  }

  tableTypeRound = () => {
    const {tabValue} = this.state;
    this.setState({
      tableType: 'round',
    });
    // update ref
    db.ref(`/tables/${tabValue}/attr`).update({
      type: 'round',
    });
  }

  findGridId = (type) => {
    const { gridLayout } = this.state;
    let count = 0;
    let found = -1;
    gridLayout.forEach(layout => {
      if (layout.i.indexOf(type) === 0) {
        // check if we have an empty stop for the first one
        let foundElement = true;
        gridLayout.forEach(layout2 => {
          if (layout2.i === `${type}-${count + 1}`) {
            foundElement = false;
          }
        });
        if (found == -1 && foundElement) {
          found = count;
        }
        count++;
      }
    });
    if (found > -1) {
      return found + 1;
    }
    return count + 1;
  }

  isATable = (item) => {
    const { gridLayout } = this.state;
    let found = -1;
    gridLayout.forEach(layout => {
      if (layout.i.indexOf('table') === 0 && layout.x === item.x && layout.y === item.y) {
        found = layout.i.replace(/[^0-9]/g, '');
      }
    });
    return found;
  }

  onDrop = (layout, layoutItem, _event) => {
    const { tableDropElement, tableGridGuests, tabValue } = this.state;
    const language = getLanguage();
    if (tableDropElement.length > 0 && layoutItem) {
      (async () => await new Promise(resolve => setTimeout(resolve, 1000)))();
      // add new item
      const newNum = this.findGridId(tableDropElement);
      layoutItem.i = tableDropElement + '-' + newNum;
      saveTableLayout(tabValue, layout);
      const tableGuests = tableGridGuests;
      tableGuests[newNum] = 0;
      this.setState({
        gridLayout: layout,
        tableGridGuests: tableGuests,
      });
    } else {
      const { tabValue, tableGridGuests, tableGridMemberDrop, tableSize } = this.state;
      // check if the user is dropped on a table
      const isATable = this.isATable(layoutItem);
      if (isATable === -1) {
        this.setState({
          openError: true,
          openErrorSuccessful: false,
          error: i18n.store.data[language].translation.welcomeErrorGuestNoTable
        });
        return;
      } else {
        const numOfGuests = tableGridGuests[isATable];
        if (numOfGuests >= tableSize) {
          this.setState({
            openError: true,
            openErrorSuccessful: false,
            error: i18n.store.data[language].translation.welcomeErrorGuestTableMax1 + ' ' + isATable + ' ' + i18n.store.data[language].translation.welcomeErrorGuestTableMax2
          });
          return;
        } else {
          // update the guests to that table and add one for that table
          addTableForGuest(tabValue, tableGridMemberDrop.familyId, tableGridMemberDrop.id, isATable, tableGridMemberDrop);
          tableGridGuests[isATable] += 1;
        }
      }
    }
  };

  gridLayoutChange = (layout) => {
    const { isDragging, tableGridLoaded, tabValue } = this.state;
    if (tableGridLoaded && !isDragging && tabValue && tabValue.length > 0) {
      saveTableLayout(tabValue, layout);
      this.setState({
        gridLayout: layout,
      });
    }
  }

  render() {
    const {adminPagination, adminUsersDeleteModal, adminUsersGuestsModal,  adminUsersEventModal, adminUsersIdField, comfirmAmountError, comment, currentTS, downloadGuestsDisabled, error, eventDetails, familyCurrentFirst, familyCurrentLast, familyError, familyModalFamilyId, familyRsvpAmount, familyRsvpCompleted, familyRsvpTotal, gridLayout, inviteCode, mouseX, mouseY, open, openError, openErrorSuccessful, openFamily, openInviteCode, pulledRSVP, needRSVP, newRegistry, perDays, pulledUsers, rsvpLater, rsvpTotal, showingDetails, showingStory, showingSocial, showingRegistry, showTableGridList, showTableGridRightClick, showTableGridRightClickX, showTableGridRightClickY, storyText, tableType, tableAllowOverlap, tableGridGuests, tableGridLoaded, tablePreventCollision, tabValue, tabSubValue, tableSize, userDetails } = this.state;

    if (userDetails == null) {
      return (
        <div className="loading"><CircularProgress /></div>
      )
    }

    if (pulledRSVP) {
      // check if we show the RSVP window
      if (!needRSVP && !open && !rsvpLater) {
        this.handleOpen();
      }
    }

    const { isSuper } = userDetails;
    let events = [];

    if (isSuper) {
      if (!pulledUsers) {
        this.adminCalls();
      }

      return (
        <div className="mt-8">
            <Header user={this.props.user}/>
            <Dialog open={adminUsersGuestsModal} onClose={this.handleUserAdminsGuestsClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Guests Details</DialogTitle>
              <DialogContent>
              {this.guestList(true)}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleUserAdminsGuestsClose} color="primary">
                  Done
                </Button>
              </DialogActions>
            </Dialog>
           <Dialog open={adminUsersEventModal} onClose={this.handleUserAdminsEventClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Event Details</DialogTitle>
            <DialogContent>
              {
                this.showUserAdminsEvent()
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleUserAdminsEventClose} color="primary">
                Done
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={adminUsersDeleteModal} onClose={this.handleUserAdminsDeleteClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Info</DialogTitle>
            <DialogContent>
              {
                this.showUserAdminsDelete()
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleUserAdminsDeleteClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleUserAdminsDeleteConfirm} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openError} onClose={this.handleErrorClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{openErrorSuccessful ? <Trans i18nKey="error">Error</Trans> : <Trans i18nKey="successful">Successful</Trans>}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleErrorClose} color="primary">
                <Trans i18nKey="close">Close</Trans>
              </Button>
            </DialogActions>
          </Dialog>
          {this.adminDeletedUsers()}
          <div className="text-l mb-2 text-center font-bold">Users</div>
          <TextField
            margin="dense"
            id="name"
            label="User ID"
            value={adminUsersIdField}
            onChange={this.handleAdminUsersIdChange}
          />
          <Button onClick={() => this.searchAdminUser()} color="primary">
            <Trans i18nKey="search">Search</Trans>
          </Button>
          <div className="text-l mb-2 text-center">{new Date((currentTS - ((perDays * 60 * 60 * 24) * (adminPagination))) * 1000).toISOString().split(".")[0]} - {new Date((currentTS - ((perDays * 60 * 60 * 24) * (adminPagination - 1))) * 1000).toISOString().split(".")[0]}</div>
          {this.adminUserList()}
        </div>
      );
    } else {
      // find all events
      const userDetailsKeys = Object.keys(userDetails);
      if (userDetailsKeys.length === 0) {
        //redirect to sign up
        if (reactLocalStorage.get('isGuest')) {
          navigate('/accessCode');
        } else {
          navigate("/newEvent");
        }
      }
      userDetailsKeys.forEach(key => {
        if (key !== 'createdAt' && key !== 'email' && key !== 'firstName' && key !== 'lastModified' && key !== 'lastName' && key !== 'device' && key !== 'locale' && key !== 'loginAuth' && key !== 'isSuper') {
          events.push(key);
        }
      });
      if (events.length === 0) {
        if (reactLocalStorage.get('isGuest')) {
          navigate('/accessCode');
        } else {
          navigate("/newEvent");
        }
      }
    }

    if (tabValue === null && events.length > 0) {
      this.getEvent(events[0]);
      this.setState({
        tabValue: events[0],
      }, () => {
        // set user data first
        if (!userDetails[events[0]].isAdmin) {
          this.getGuestData();
        }
      });
    }

    return (
      <div>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><Trans i18nKey="rsvpDialogTitle">RSVP for Event</Trans></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans i18nKey="rsvpAmount1">You have</Trans> {rsvpTotal} <Trans i18nKey="rsvpAmount2">tickets for the event. You can RSVP now or later.</Trans>
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              label={<Trans i18nKey="rsvpAmount">RSVP Amount</Trans>}
              type="number"
              onChange={this.handleTextChange}
              fullWidth
              error={comfirmAmountError}
            />
            {comfirmAmountError && (
              <div class='error'><Trans i18nKey="rsvpAmountError">RSVP amount must be between 0 and</Trans> {rsvpTotal}.</div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <Trans i18nKey="rsvpLater">RSVP Later</Trans>
            </Button>
            <Button onClick={this.comfirmAmount} color="primary">
              <Trans i18nKey="confirmAmount">Confirm Amount</Trans>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openInviteCode} onClose={this.handleInviteCodeClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><Trans i18nKey="inviteCodeShare">Share your invitation code</Trans></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans i18nKey="inviteText1">This is your invitation link so you can share with your guest all the event datails. Note: Download the</Trans> <a target="_blank" href="https://specialday.app/invite">Special Day app</a> <Trans i18nKey="inviteText2">to share, via text, email, and whatsapp from your phone.</Trans>
            </DialogContentText>
            <TextField
              margin="dense"
              label={<Trans i18nKey="inviteCode">Invite Code</Trans>}
              value={inviteCode}
              InputProps={{
                readOnly: true,
              }}
            />
            <a href={"mailto:?subject=I am having an Event&body=Hi! Your friend " + userDetails.firstName + " wants to invite you to an amazing and very special event. Please download the App called: A Special Day and enter the following code: " + inviteCode + " to get all the event details. Or click the link to download on your iOS or Android device https://specialday.app/store.html"}><Button onClick={this.handleInviteCodeClose} color="primary">
              <Trans i18nKey="openEmailLink">Open Up Email Client</Trans>
            </Button></a>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleInviteCodeClose} color="primary">
              <Trans i18nKey="close">Close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openFamily} onClose={this.handleFamilyClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><Trans i18nKey="familyDetails">Family Details</Trans></DialogTitle>
          <DialogContent>
            {
              this.showCurrentFamilies()
            }
            <TextField
              margin="dense"
              id="firstName"
              label={<Trans i18nKey="firstName">First Name</Trans>}
              onChange={this.handleFirstNameChange}
              value={familyCurrentFirst}
              error={familyError}
            />
            <TextField
              margin="dense"
              id="lastName"
              label={<Trans i18nKey="lastName">Last Name</Trans>}
              onChange={this.handleLastNameChange}
              value={familyCurrentLast}
              error={familyError}
            />
            <br/>
            <Button variant="contained" onClick={this.addMember} color="primary"><Trans i18nKey="addMember">Add Member</Trans></Button>
            <br/>
            <FormControlLabel
              control={<Checkbox checked={familyRsvpCompleted} onChange={this.handleRsvpCompleted} name="rsvpCompleted" />}
              label={<Trans i18nKey="rsvpCompleted">RSVP Completed</Trans>}
            />
            <br/>
            <TextField
              margin="dense"
              id="rsvpAmount"
              label={<Trans i18nKey="rsvpAmount">RSVP Amount</Trans>}
              onChange={this.handleRsvpAmount}
              value={familyRsvpAmount}
              type="number"
            />
            <TextField
              margin="dense"
              id="rsvpTotal"
              label={<Trans i18nKey="rsvpTotal">RSVP Total</Trans>}
              onChange={this.handleRsvpTotal}
              value={familyRsvpTotal}
              type="number"
            />
            {familyError && (
              <div class='error'>{familyError}</div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleFamilyClose} color="primary">
              <Trans i18nKey="cancel">Cancel</Trans>
            </Button>
            <Button onClick={this.familyDone} color="primary">
              <Trans i18nKey="done">Done</Trans>
            </Button>
            {familyModalFamilyId.length === 0 && (<Button onClick={this.familyAddAnother} color="primary">
              <Trans i18nKey="addAnother">Add Another</Trans>
            </Button>)}
          </DialogActions>
        </Dialog>
        <Header user={this.props.user}/>
        {events.map((event, index) =>
          <div>
            {events.length !== 1 && (
              <Tabs value={tabValue} onChange={this.handleChange}>
                <Tab value={event} label={index + 1} onClick={() => this.getEvent(event)} />
              </Tabs>
            )}
            <TabPanel value={tabValue} index={event}>
              { userDetails[event].isAdmin && (
                <div>
                  <div><Trans i18nKey="typeOfParty">Type of Party</Trans>: {(eventDetails ? eventDetails.type : null)}</div>
                  <div className="pt-4"><Trans i18nKey="partyFor">Party for</Trans>: {(eventDetails ? eventDetails.bride : null)}</div>
                  <div>{(eventDetails ? (eventDetails.type === 'Wedding' ? 'Groom: ' : null) : null)}{(eventDetails ? (eventDetails.type === 'Wedding' ? eventDetails.groom : null) : null)}</div>
                  {(userDetails[event].isAdmin ? (<div className="pt-4"><Trans i18nKey="code">Code</Trans>: {(eventDetails ? eventDetails.code : null)}</div>):null)}
                  {(userDetails[event].isAdmin ? (<Button variant="contained" onClick={() => {this.shareInvite(eventDetails ? eventDetails.code : null)}} color="primary"><Trans i18nKey="shareCode">Share Code</Trans></Button>):null)}
                  {/* {(userDetails[event].isAdmin ? (<div className="pt-4">Primary Color: {(eventDetails ? eventDetails.primary : null)}</div>):null)}
                  {(userDetails[event].isAdmin ? (<div className="pt-4">Secondary Color: {(eventDetails ? eventDetails.secondary : null)}</div>):null)} */}
                  <Tabs value={tabSubValue} onChange={this.handleSubChange}>
                    <Tab value={'Addresses'} label={<Trans i18nKey="addresses">Addresses</Trans>}/>
                    <Tab value={'Story'} label={<Trans i18nKey="story">Story</Trans>}/>
                    <Tab value={'Registry'} label={<Trans i18nKey="registry">Registry</Trans>} />
                    {(userDetails[event].isAdmin ? (<Tab value={'Guests'} label={<Trans i18nKey="guests">Guests</Trans>} onClick={() => this.getGuests(event)} />): null)}
                    <Tab value={'Tables'} label={<Trans i18nKey="tables">Tables</Trans>} onClick={() => this.getTables(event)}/>
                    <Tab value={'Comments'} label={<Trans i18nKey="comments">Comments</Trans>} onClick={() => this.getComments(event)}/>
                    {/* {(userDetails[event].isAdmin ? (<Tab value={'Hotels'} label={'Hotels'} />): null)}
                    {(eventDetails && eventDetails.hasSurvey && <Tab value={'Surveys'} label={'Surveys'} onClick={() => this.getSurveyResponses(event)} />)} */}
                  </Tabs>
                  <TabPanel value={tabSubValue} index={'Addresses'}>
                    {eventDetails && eventDetails.addresses ? (
                      this.addressesList(event, eventDetails.addresses)
                    ) : 'Currently no addresses, please use the app to add.'}
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Story'}>
                    {eventDetails && eventDetails.stories ? (
                      <div className="pb-40">
                        <Trans i18nKey="welcomeStoryText">You can add your story below.</Trans>
                        <div className="text-gray-400 pb-4">
                          *<Trans i18nKey="welcomeStoryDownload">To add or update an image please download the mobile app to do so</Trans>: <a target="_blank" href="https://apps.apple.com/us/app/a-special-day/id1380351953" className="text-blue-400 hover:text-blue-300 ml-4">
                          Apple
                          </a>
                          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.codingpengins.app.ptwedding" className="text-blue-400 hover:text-blue-300 ml-4">
                            Google
                          </a>
                        </div>
                        <img className={"story-img"} src={eventDetails.stories.story.img} />
                        <div className="mt-5">
                          <TextField
                            id="story-text"
                            label={<Trans i18nKey="welcomeStoryTextField">Story Text</Trans>}
                            multiline
                            fullWidth
                            maxRows={10}
                            variant="outlined"
                            onChange={(event) => this.handleStoryChange(event)}
                            value={storyText}
                          />
                          <div className="mt-3"><Button variant={"contained"} onClick={this.saveStory} color="primary"><Trans i18nKey="save">Save</Trans></Button></div>
                        </div>
                      </div>
                    ) : 'Currently no story, please use the app to add.'}
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Registry'}>
                    <TextField
                      margin="dense"
                      id="new-registry"
                      label={<Trans i18nKey="addRegistry">Add Registry</Trans>}
                      value={newRegistry}
                      onChange = {(event) => this.handleNewRegistryChange(event)}
                    />
                    <div className="ml-5 mt-4 inline-block"><Button variant={"contained"} onClick={this.addRegistry} color="primary"><Trans i18nKey="add">Add</Trans></Button></div>
                    {eventDetails && eventDetails.registries ? (
                      this.registriesList(userDetails[event].isAdmin, eventDetails.registries)
                    ) : ' Lets add a registry for your event!'}
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Guests'}>
                    <Button variant="contained" onClick={this.addFamilyModal} color="primary"><Trans i18nKey="addFamily">Add Family</Trans></Button>
                    <Button disabled={downloadGuestsDisabled ? true : false} className="float-right" variant="contained" onClick={this.downloadGuests} color="primary"><Trans i18nKey="downloadGuests">Download Guest List</Trans></Button>
                    <div className="pb-5"></div>
                    {this.guestList()}
                    <div className="pb-40"></div>
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Tables'}>
                    <div className="text-3xl pb-4"><Trans i18nKey="tableArragement">Table Arrangement</Trans></div>
                    { !tableGridLoaded && (<div><Trans i18nKey="loading">Loading</Trans></div>)}
                    { tableGridLoaded && (<div>
                      <p><Trans i18nKey="tableType">Choose the type of table for your event</Trans></p>
                      <Button variant={tableType === "square" ? "contained" : "outlined"} onClick={this.tableTypeSquare} color="primary"><Trans i18nKey="tableTypeSquare">Square</Trans></Button>
                      &nbsp;
                      <Button variant={tableType === "round" ? "contained" : "outlined"} onClick={this.tableTypeRound} color="primary"><Trans i18nKey="tableTypeRound">Round</Trans></Button>
                      <div className="pb-5"></div>
                      <div><Trans i18nKey="tableNumber">Table number</Trans>:</div>
                      <TextField
                        margin="dense"
                        id="table-size"
                        label={<Trans i18nKey="tableSize">Size of table</Trans>}
                        value={tableSize}
                        type="number"
                        onChange = {(event) => this.handleTableChange(event)}
                      />
                      <div className="text-2xl pb-4 pt-4"><Trans i18nKey="tableMap">Event Map</Trans></div>
                      <p><Trans i18nKey="tableMapDetails">Please click on each seat to add a guest's name. You can also remove or add tables and spaces.</Trans></p>
                      <div className={tableType === "square" ? "table-new-table-square": "table-new-table-round"} draggable={true} onDragStart={() => this.onDragStartElement('table')} onDragEnd={this.onDragEndElement}>
                        <Trans i18nKey="tableNewTable">New Table</Trans>
                      </div>
                      <div className="table-new-space" draggable={true} onDragStart={() => this.onDragStartElement('space')} onDragEnd={this.onDragEndElement}>
                        <Trans i18nKey="tableNewSpace">Empty Space</Trans>
                      </div>
                      <div>
                        {/* https://github.com/react-grid-layout/react-grid-layout */}
                        <GridLayout allowOverlap={tableAllowOverlap} preventCollision={tablePreventCollision} onDrop={this.onDrop} isDroppable={true} className="table-grid" onLayoutChange={this.gridLayoutChange} layout={gridLayout} cols={12} rowHeight={73} width={1000} onDragStart={this.onDragStartGrid} onDragStop={this.onDragEndGrid}>
                        {gridLayout.map((item) => (
                          <div key={item.i} className={[(item.i === "mesa" || item.i === "dancing" ? 
                          "table-item" : (item.i.indexOf("space") === 0 ? "table-item-space": (tableType === "square" ? "table-item-square": "table-item-round"))), (item.i.indexOf("table") === 0 ? (tableGridGuests[item.i.replace(/[^0-9]/g, '')] == tableSize ? 'table-full' : null) : null)]} onMouseOver={() => this.onMouseOver(item.i)} onMouseOut={this.onMouseOut}>{(item.i.indexOf("space") === 0 ? "Space" : (item.i.indexOf("table") === 0 ? "Table " + item.i.replace(/[^0-9]/g, '') : item.i))}<div>{( item.i.indexOf("table") === 0 ? tableGridGuests[item.i.replace(/[^0-9]/g, '')]+"/"+tableSize : null)}</div></div>
                        ))}
                        </GridLayout>
                        <div className="table-grid-guest-list">
                          {this.guestListMap()}
                        </div>
                      </div>
                      { showTableGridList && (<div className="table-grid-list" style={{top: mouseY + 10, left: mouseX + 10}}>
                        {this.tableGuestList()}
                      </div>)}
                      { showTableGridRightClick && (<div className="table-grid-list" style={{top: showTableGridRightClickY + 10, left: showTableGridRightClickX + 10}}>
                        {this.tableRightClickMenu()}
                      </div>)}
                    </div>)}
                    {/* <Button variant="contained" onClick={this.addFamilyModal} color="primary">Save</Button> */}
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Comments'}>
                    <div className="comment-new">
                      <TextField
                        margin="dense"
                        id="comment"
                        label={<Trans i18nKey="commentPlaceholder">Type your comment here</Trans>}
                        value={comment}
                        onChange={this.handleCommentChange}
                        className="my-1 p-1 w-full"
                      />
                      <Button variant="contained" onClick={this.postComment} color="primary"><Trans i18nKey="submit">Submit</Trans></Button>
                    </div>
                    {this.comments()}
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Hotels'}>
                    <div><Trans i18nKey="comingSoon">Coming Soon</Trans></div>
                  </TabPanel>
                  <TabPanel value={tabSubValue} index={'Surveys'}>
                    <div>
                    <Trans i18nKey="currentSurveys">Current Surveys</Trans>: <Trans i18nKey="comingSoon">Coming Soon</Trans>
                    </div>
                    <div>
                      {this.surveyResponses()}
                    </div>
                  </TabPanel>
                </div>
              )}
              { !userDetails[event].isAdmin && (
                <div>
                  <Typography className="text-center font-bold pb-10" fontSize={30} variant="caption" component="div" color="text.secondary">
                    <Trans i18nKey="hello">Hello</Trans>, {userDetails.firstName}
                  </Typography>
                  {rsvpLater && (
                    <div className="text-center pb-10">
                      <Button variant="contained" onClick={this.handleOpen} color="primary"><Trans i18nKey="actionRSVP">Click to RSVP</Trans></Button>
                    </div>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <CircularProgressWithLabel value={90} eventDetails={eventDetails}/>
                    </Grid>
                    {!showingDetails && !showingStory && !showingSocial && !showingRegistry && (
                      <Grid item xs={6}>
                        <Grid item xs={6}>
                          <Event style={{ fontSize: 150, cursor: 'pointer' }} color="primary" onClick={() => this.showEventDetails(event)} />
                          <Favorite style={{ fontSize: 150, cursor: 'pointer' }} color="primary" onClick={() => this.showStory(event)}/>
                        </Grid>
                        <Grid item xs={6}>
                          <Forum style={{ fontSize: 150, cursor: 'pointer' }} color="primary" onClick={() => this.showSocial(event)}/>
                          <CardGiftcard style={{ fontSize: 150, cursor: 'pointer' }} color="primary" onClick={() => this.showRegistry(event)}/>
                        </Grid>
                      </Grid>
                    )}
                    {showingDetails && (
                      <Grid item xs={6}>
                        <button className="text-blue-500 hover:text-blue-600" onClick={() => this.clearSub()}>&lt; <Trans i18nKey="back">Back</Trans></button>
                        {eventDetails && eventDetails.addresses ? (
                          this.addressesList(event, eventDetails.addresses)
                        ) : <Trans i18nKey="addressesNo">Currently no addresses, please use the app to add.</Trans>}
                      </Grid>
                    )}
                    {showingStory && (
                      <Grid item xs={6}>
                        <button className="text-blue-500 hover:text-blue-600" onClick={() => this.clearSub()}>&lt; <Trans i18nKey="back">Back</Trans></button>
                        {eventDetails && eventDetails.stories ? (
                          <div>
                            <img className={"story-img"} src={eventDetails.stories.story.img} />
                            <div><Trans i18nKey="story">Story</Trans>: {eventDetails.stories.story.text}</div>
                          </div>
                        ) : <Trans i18nKey="storyNo">Currently no story, please use the app to add.</Trans>}
                      </Grid>
                    )}
                    {showingRegistry && (
                      <Grid item xs={6}>
                        <button className="text-blue-500 hover:text-blue-600" onClick={() => this.clearSub()}>&lt; <Trans i18nKey="back">Back</Trans></button>
                        {eventDetails && eventDetails.registries ? (
                          this.registriesList(userDetails[event].isAdmin, eventDetails.registries)
                        ) : (<div><Trans i18nKey="registryNo">Currently no registries. Check back later.</Trans></div>)}
                      </Grid>
                    )}
                    {showingSocial && (
                      <Grid item xs={6}>
                        <button className="text-blue-500 hover:text-blue-600" onClick={() => this.clearSub()}>&lt; <Trans i18nKey="back">Back</Trans></button>
                        <div className="comment-new">
                          <input
                            type="email"
                            className="my-1 p-1 w-full"
                            name="comment"
                            value={comment}
                            label={<Trans i18nKey="commentPlaceholder">Type your comment here</Trans>}
                            id="comment"
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <Button variant="contained" onClick={this.postComment} color="primary">Submit</Button>
                        </div>
                        {this.comments()}
                      </Grid>
                    )}
                  </Grid>
                  {eventDetails && (
                    <Typography className="text-center font-bold pb-10" fontSize={26} variant="caption" component="div" color="text.secondary">
                      {eventDetails.type === 'Boda' || eventDetails.type === 'Wedding' ? this.capitalizeTxt(eventDetails.bride) + 
                      ' & ' + this.capitalizeTxt(eventDetails.groom) + ' ' + eventDetails.type : this.capitalizeTxt(eventDetails.bride) + ' ' + eventDetails.type}
                    </Typography>
                  )}
                </div>
              )}
            </TabPanel>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
export default Welcome;