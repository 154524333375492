import React, { Component } from "react";
import { auth, db } from "../firebaseConfig";

// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import {reactLocalStorage} from 'reactjs-localstorage';

import Footer from "./Footer.js";

import { Trans } from "react-i18next";
import Header from "./Header";
import { UserContext } from "../providers/UserProvider";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`wrapped-tabpanel-${index}`}
//       aria-labelledby={`wrapped-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// const block = {
//   marginBottom: 20,
//   backgroundColor: "lightgrey",
//   padding: 10,
// };

class ProfilePage extends Component {

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      tabValue: "zero",
      users: [],
      events: [],
      errors: [],
    }
  }

  componentDidMount = () => {
    const user = this.context;
    db.ref(`/users/${user.uid}`).on('value', querySnapShot => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        user,
        userDetails: {...data}
      });
    });
  }

  getUsers = () => {
    db.ref(`/users`).on('value', querySnapShot => {
      let users = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        users,
      });
    });
  }

  getEvents = () => {
    db.ref(`/events`).on('value', querySnapShot => {
      let events = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        events
      });
    });
  }

  getErrors = () => {
    db.ref(`/errors`).on('value', querySnapShot => {
      let errors = querySnapShot.val() ? querySnapShot.val() : {};
      this.setState({
        errors
      });
    });
  }

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    })
  };

  renderUserType = (type) => {
    if (type === 'guest') {
      return 'Guest';
    } else if (type === 'adminEvent') {
      return 'Event Admin';
    } else if (type === 'adminSuper') {
      return 'Super Admin';
    }
  }

  convertLocale = (abv) => {
    let shortAbv = abv;
    if (shortAbv === null || typeof(shortAbv) === 'undefined' || shortAbv.length === 0) {
      return 'N/A';
    }
    if (abv.indexOf('-') > -1) {
      shortAbv = abv.substr(0, abv.indexOf('-')); 
    }
    if (shortAbv === 'en') {
      return 'English';
    } else if (shortAbv === 'es') {
      return 'Español';
    }
    return 'N/A';
  }

  render() {
    const { errors, events, tabValue, userDetails, users } = this.state;

    if (userDetails == null) {
      return (
        <div>Loading...</div>
      )
    }
    const {photoURL, displayName, email} = this.props.user;
    const { firstName, lastName, locale, isSuper } = userDetails;

    let userType = 'guest';
    if (isSuper) {
      userType = 'adminSuper';
    } else {
      Object.keys(userDetails).forEach(element => {
        if(typeof userDetails[element] === 'object') {
          const { isAdmin } = userDetails[element];
          if (isAdmin) {
            userType = 'adminEvent';
          }
        }
      });
    }

    const userKeys = Object.keys(users);
    const eventKeys = Object.keys(events);
    const errorKeys = Object.keys(errors);

    let userOptions = null;
    // if (userType === 'adminSuper') {
    //   // show more options
    //   userOptions = (
    //     <div>
    //       <Tabs value={tabValue} onChange={this.handleChange} aria-label="wrapped label tabs example">
    //         <Tab value="one" label="Users" onClick={this.getUsers} />
    //         <Tab value="two" label="Events" onClick={this.getEvents} />
    //         <Tab value="three" label="Errors" onClick={this.getErrors}/>
    //       </Tabs>
    //       <TabPanel value={tabValue} index="one">
    //         <div>Total: {userKeys.length}</div>
    //         {userKeys.map((userKey) =>
    //           <div style={block}>
    //             <div>id: {userKey}</div>
    //             {Object.keys(users[userKey]).map((userInfoKey) => {
    //               if(typeof users[userKey][userInfoKey] === 'object') {
    //                 const userDetailsEvent = Object.keys(users[userKey][userInfoKey]).map((userDetailKey) => {
    //                   if (typeof users[userKey][userInfoKey][userDetailKey] === 'boolean') {
    //                     return (
    //                       <div>
    //                         {userDetailKey}: {users[userKey][userInfoKey][userDetailKey] ? 'true' : 'false'}
    //                       </div>
    //                     );
    //                   } else {
    //                     return (
    //                       <div>
    //                         {userDetailKey}: {users[userKey][userInfoKey][userDetailKey]}
    //                       </div>
    //                     );
    //                   }
    //                 });
    //                 return (<div><div>event id: {userInfoKey}</div>{userDetailsEvent}</div>);
    //               }
    //               return (<div>{userInfoKey}: {users[userKey][userInfoKey]}</div>)
    //             })}
    //           </div>
    //         )}
    //       </TabPanel>
    //       <TabPanel value={tabValue} index="two">
    //         {eventKeys.map((eventKey) => 
    //           <div style={block}>
    //             <div>id: {eventKey}</div>
    //             {Object.keys(events[eventKey]).map((eventsEventKey) => {
    //               if(typeof events[eventKey][eventsEventKey] === 'object') {
    //                 if (eventsEventKey === 'stories') {
    //                   let img = null;
    //                   if(events[eventKey][eventsEventKey].story.img) {
    //                     img = (<div><div>img url: {events[eventKey][eventsEventKey].story.img}</div><img src={events[eventKey][eventsEventKey].story.img}></img></div>);
    //                   }
    //                   return (
    //                     <div>
    //                       <div>{events[eventKey][eventsEventKey].story.text}</div>
    //                       {img}
    //                     </div>
    //                   );
    //                 } else if(eventsEventKey === 'addresses') {
    //                   return Object.keys(events[eventKey][eventsEventKey]).map((addressKey) => {
    //                     const address = Object.keys(events[eventKey][eventsEventKey][addressKey]).map((addressDetailKey) => {
    //                       return (
    //                         <div>
    //                           {addressDetailKey}: {events[eventKey][eventsEventKey][addressKey][addressDetailKey]}
    //                         </div>
    //                       );
    //                     });
    //                     return (<div>{address}</div>);
    //                   });
    //                 } else if(eventsEventKey === 'registries') {
    //                   let regString = '';
    //                   events[eventKey][eventsEventKey].forEach(registry => {
    //                     regString += registry + ', ';
    //                   });
    //                   return (
    //                     <div>Registries: {regString}</div>
    //                   )
    //                 } else {
    //                   console.log(eventsEventKey);
    //                 }
    //                 return;
    //               }
    //               return (<div>{eventsEventKey}: {events[eventKey][eventsEventKey]}</div>)
    //             })}
    //           </div>
    //         )}
    //       </TabPanel>
    //       <TabPanel value={tabValue} index="three">
    //         {errorKeys.map((errorKey) => 
    //           <div style={block}>
    //             <div>event id: {errorKey}</div>
    //             {Object.keys(errors[errorKey]).map((errorErrorKey) => {
    //               const errorDetails = Object.keys(errors[errorKey][errorErrorKey]).map((errorDetail) => {
    //                 return (<div>{errorDetail}: {errors[errorKey][errorErrorKey][errorDetail]}</div>);
    //               })
    //               return (<div><div>id: {errorErrorKey}</div>{errorDetails}</div>)
    //             })}
    //           </div>
    //         )}
    //       </TabPanel>
    //     </div>
    //   );
    // }

    return (
      <div>
        <Header user={this.props.user}/>
        <div className = "mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
          <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
            {/* <div
              style={{
                background: `url(${photoURL || 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png'})  no-repeat center center`,
                backgroundSize: "cover",
                height: "200px",
                width: "200px"
              }}
              className="border border-blue-300"
            ></div> */}
            <div className = "md:pl-4">
            <h2>{firstName}</h2>
            <h2>{this.convertLocale(locale)}</h2>
            <h2 className = "text-2xl font-semibold">{displayName}</h2>
            <h3 className = "italic">{email}</h3>
            {/* <h3>{this.renderUserType(userType)}</h3> */}
            </div>
          </div>
          {userOptions}
          <button className = "w-full py-3 bg-red-600 mt-4 text-white" onClick = {() => {auth.signOut(); reactLocalStorage.remove('isGuest'); reactLocalStorage.remove('familyId'); reactLocalStorage.remove('guestId'); reactLocalStorage.remove('eventId');}}><Trans i18nKey="signOut">Sign out</Trans></button>
        </div>
        <Footer></Footer>
      </div>
    ) 
  }
}
export default ProfilePage;
