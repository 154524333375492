import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import { navigate } from "@reach/router";
import {reactLocalStorage} from 'reactjs-localstorage';
import { Trans } from "react-i18next";

import { getEventIdSearch, getFirstLastSearch } from "../firebaseConfig";
import i18n from '../i18n';

import Footer from "./Footer.js";
import Header from "./Header";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const getLanguage = () => {
    const lang = i18n.language;
    const langCode = lang.split('-')[0];
    let currentLang = 'en';
    if(Object.keys(i18n.store.data).includes(langCode)) {
      currentLang = langCode;
    }
    return currentLang;
}

class AccessCodePage extends Component {
    constructor(props) {
        super(props);
        reactLocalStorage.set('eventId', "");
        this.state = {
            openError: false,
          search: "",
          firstName: "",
          lastName: "",
          eventId: "",
          error: null,
        }
    }

    handleErrorClose = () => {
        this.setState({
            openError: false,
        });
    }

    searchAccessCode = async (event, search) => {
        const language = getLanguage();
        if (search.length === 0) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.accessCodeEmpty
              });
            return;
        }

        const hasValid = await getEventIdSearch(search);
        if (hasValid) {
            // show register page
            this.setState({
                eventId: reactLocalStorage.get('eventId'),
            });
        } else {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.accessCodeInvalid
            });
            return;
        }
    }

    searchFirstLast = async (event, firstName, lastName) => {
        const hasValid = await getFirstLastSearch(this.props.user, firstName, lastName);
        const language = getLanguage();
        if (hasValid) {
            // show register page
            navigate("/welcome");
        } else {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.accessCodeUsed
            });
            return;
        }
    }

    onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'search') {
            this.setState({
                search: value,
            });
        } else if(name === 'firstName') {
            this.setState({
                firstName: value,
            });
        } else if(name === 'lastName') {
            this.setState({
                lastName: value,
            });
        }
    };

    render() {
        const { error, eventId, firstName, lastName, openError, search } = this.state;
        const language = getLanguage();
        return (
            <div>
                <Header user={this.props.user}/>
                <Dialog open={openError} onClose={this.handleErrorClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Trans i18nKey="error">Error</Trans></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleErrorClose} color="primary">
                            <Trans i18nKey="close">Close</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="main-content relative">
                    <div className="absolute w-full h-full top-0">
                        {eventId.length === 0 && (<div className="h-3/5 m-auto text-center text-gray-500 mt-20">
                            <h1 className="text-4xl mb-2 mb-12"><Trans i18nKey="accessCode">Access code</Trans></h1>
                            <div className="mb-8"><Trans i18nKey="accessCodeDesc">Thank you for visiting Special Day. Please type the access code you received on your invitation</Trans></div>
                            <TextField
                                inputProps={{min: 0, style: { textAlign: 'center', textTransform: 'uppercase' }}}
                                type="text"
                                name="search"
                                value={search}
                                placeholder={i18n.store.data[language].translation.accessCode}
                                id="search"
                                onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <button className="ml-10 mt-2 bg-blue-500 hover:bg-blue-600 w-24 py-2 text-white" onClick={event => {
                                this.searchAccessCode(event, search);
                            }}><Trans i18nKey="search">Search</Trans></button>
                        </div>)}
                        {eventId.length > 0 && (<div className="h-3/5 m-auto text-center text-gray-500 mt-20">
                            <h1 className="text-4xl mb-2 mb-12"><Trans i18nKey="accessCodeFound">We Found Your Event</Trans></h1>
                            <div className="mb-8"><Trans i18nKey="accessCodeFoundDesc">Please enter to your first and last name to find your reservation</Trans></div>
                            <TextField
                                type="text"
                                name="firstName"
                                value={firstName}
                                placeholder={i18n.store.data[language].translation.firstName}
                                id="firstName"
                                onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <TextField
                                type="text"
                                name="lastName"
                                value={lastName}
                                placeholder={i18n.store.data[language].translation.lastName}
                                id="lastName"
                                onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <button className="ml-10 mt-2 bg-blue-500 hover:bg-blue-600 w-24 py-2 text-white" onClick={event => {
                                this.searchFirstLast(event, firstName, lastName);
                            }}><Trans i18nKey="search">Search</Trans></button>
                        </div>)}
                    </div>
                </div>
                <Footer></Footer>
            </div>
        ) 
    }
}
export default AccessCodePage;

