import React, { Component } from "react";
import { Link } from "@reach/router";
import { auth, signInWithGoogle, signInWithFacebook } from "../firebaseConfig";
import { Trans } from "react-i18next";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from "./Footer.js";
import Header from "./Header";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../i18n';

// const changeLanguage = lng => {
//   i18n.changeLanguage(lng);
// };
// const { t, i18n } = useTranslation();

const getLanguage = () => {
  const lang = i18n.language;
  const langCode = lang.split('-')[0];
  let currentLang = 'en';
  if(Object.keys(i18n.store.data).includes(langCode)) {
    currentLang = langCode;
  }
  return currentLang;
}

class SignUpPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      open: false,
      termsAgree: false,
      error: null,
      recaptchaToken: '',
    }
  }

  onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      this.setState({
        email: value,
      });
    } else if (name === "userPassword") {
      this.setState({
        password: value,
      });
    } else if (name === "userPassword2") {
      this.setState({
        password2: value,
      });
    } else if (name === "firstName") {
      this.setState({
        firstName: value,
      });
    } else if (name === "lastName") {
      this.setState({
        firstName: value,
      });
    } else if (name === "termsAgree") {
      this.setState({
        termsAgree: value,
      });
    }
  };

  createUserWithGoogle = () => {
    const {recaptchaToken, termsAgree} = this.state;
    const language = getLanguage();
    if (!termsAgree) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoTerms
      });
      return;
    } else if (!recaptchaToken || recaptchaToken.length === 0) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoCatch
      });
      return;
    }
    signInWithGoogle();
  }

  createUserWithFacebook = () => {
    const {recaptchaToken, termsAgree} = this.state;
    const language = getLanguage();
    if (!termsAgree) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoTerms
      });
      return;
    } else if (!recaptchaToken || recaptchaToken.length === 0) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoCatch
      });
      return;
    }
    signInWithFacebook();
  }

  createUserWithEmailAndPasswordHandler = async (event, email, password, password2, termsAgree) => {
    const {recaptchaToken} = this.state;
    event.preventDefault();
    const language = getLanguage();
    // check if email and password is filled
    if (email.trim().length === 0 && password.trim().length === 0) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorEmailPassword
      });
      return;
    } else if (password.trim() !== password2.trim()) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorPasswordMismatch
      });
      return;
    } else if (!termsAgree) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoTerms
      });
      return;
    } else if (!recaptchaToken || recaptchaToken.length === 0) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorNoCatch
      });
      return;
    }
    try {
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
    //   generateUserDocument(user, {displayName});
    } catch(error) {
      this.setState({
        open: true,
        error: i18n.store.data[language].translation.signUpErrorFatal
      });
    }
    this.setState({
      email: "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      termsAgree: false,
      error: null,
      recaptchaToken: '',
    });
  };

  handleVerify = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { email, open, password, password2, firstName, lastName, termsAgree, error } = this.state;
    return (
      <div className="mt-8">
        <Header />
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><Trans i18nKey="error">Error</Trans></DialogTitle>
          <DialogContent>
            <DialogContentText>
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <Trans i18nKey="close">Close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
        <h1 className="text-3xl mb-2 text-center font-bold"><Trans i18nKey="signUp">Sign Up</Trans></h1>
        {/* <button onClick={() => changeLanguage("es")}>es</button>
        <button onClick={() => changeLanguage("en")}>en</button> */}
        <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8 mb-10">
          {/* {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )} */}
          <button
            className="bg-red-500 hover:bg-red-600 w-full py-2 text-white"
            onClick = {(event) => {this.createUserWithGoogle()}}>
            <Trans i18nKey="signUpGoogle">Sign Up with Google</Trans>
          </button>
          <p className="text-center my-3"><Trans i18nKey="or">or</Trans></p>
          <button
            className="bg-blue-500 hover:bg-blue-600 w-full py-2 text-white"
            onClick = {(event) => {this.createUserWithFacebook()}}>
            <Trans i18nKey="signUpFacebook">Sign Up with Facebook</Trans>
          </button>
          <p className="text-center my-3"><Trans i18nKey="or">or</Trans></p>
          <form className="">
            {/* <label htmlFor="firstName" className="block">
              First Name:
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="firstName"
              value={firstName}
              placeholder="E.g: Vanesa"
              id="firstName"
              onChange={event => this.onChangeHandler(event)}
            />
            <label htmlFor="lastName" className="block">
              Last Name:
            </label>
            <input
              type="text"
              className="my-1 p-1 w-full "
              name="lastName"
              value={lastName}
              placeholder="E.g: Hernández"
              id="lastName"
              onChange={event => this.onChangeHandler(event)}
            /> */}
            <label htmlFor="userEmail" className="block">
              <Trans i18nKey="email">Email</Trans>:
            </label>
            <input
              type="email"
              className="my-1 p-1 w-full"
              name="userEmail"
              value={email}
              placeholder="user@gmail.com"
              id="userEmail"
              onChange={event => this.onChangeHandler(event)}
            />
            <label htmlFor="userPassword" className="block">
              <Trans i18nKey="signUpNewPassword">Create Password</Trans>:
            </label>
            <input
              type="password"
              className="mt-1 mb-1 p-1 w-full"
              name="userPassword"
              value={password}
              placeholder="abcd1234"
              id="userPassword"
              onChange={event => this.onChangeHandler(event)}
            />
            <label htmlFor="userPassword2" className="block">
              <Trans i18nKey="signUpReenterPassword">Re-enter Password</Trans>:
            </label>
            <input
              type="password"
              className="mt-1 mb-3 p-1 w-full"
              name="userPassword2"
              value={password2}
              placeholder="abcd1234"
              id="userPassword2"
              onChange={event => this.onChangeHandler(event)}
            />
            <button
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              onClick={event => {
                this.createUserWithEmailAndPasswordHandler(event, email, password, password2, termsAgree);
              }}
            >
              <Trans i18nKey="signUpEmail">Sign Up via Email</Trans>
            </button>
          </form>
          <p className="text-center my-3">
            <input
              type="checkbox"
              name="termsAgree"
              value={termsAgree}
              id="termsAgree"
              onChange={event => this.onChangeHandler(event)}
            />
            <label htmlFor="termsAgree">
              &nbsp; <Trans i18nKey="signUpTerms1">Agree to terms and conditions and our privacy policy.</Trans> <a href="/termsandconditions" target="_blank" className="text-blue-500 hover:text-blue-600"><Trans i18nKey="signUpTerms2">View them click here</Trans></a>
            </label>
          </p>
          <p className="text-center my-3">
            <Trans i18nKey="signUpHaveAccount">Already have an account?</Trans>{" "}
            <Link to="/signIn" className="text-blue-500 hover:text-blue-600">
              <Trans i18nKey="signUpSignIn">Sign in here</Trans>
            </Link>
          </p>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey="6Lci1e0cAAAAAMCv9g1oE75vVsxwq-RYrghkT7VB">
          <GoogleReCaptcha onVerify={this.handleVerify} />
        </GoogleReCaptchaProvider>,
        <Footer></Footer>
      </div>
    );
  }
}

export default SignUpPage;