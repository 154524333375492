import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import {reactLocalStorage} from 'reactjs-localstorage';
import { Trans } from "react-i18next";
import Footer from "./Footer.js"
import Header from "./Header.js";

class GettingStartedPage extends Component {
    accessCode() {
        reactLocalStorage.set('isGuest', true);
        navigate("/signUp");
    }

  render() {
    return (
        <div>
            <Header user={this.props.user}/>
            <div className="main-content relative">
                <img src="images/gettingStarted.jpeg" loading="lazy" alt="" className="getting-started-bg"/>
                <div className="absolute w-full h-full top-0 bg-black bg-opacity-50"></div>
                <div className="absolute w-full h-full top-0">
                    <div className="h-3/5 m-auto text-center text-white mt-40">
                        <h1 className="text-4xl mb-2 font-bold mb-12"><Trans i18nKey="getStarted">Let's get started</Trans>!</h1>
                        <div>
                            <div className="inline-block font-bold">
                                <div><Trans i18nKey="gettingStartedNewEvent">Want to create an event for free?</Trans></div>
                                <Link to="/signUp">
                                    <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-full py-2 text-white"><Trans i18nKey="signUp">Sign up</Trans></button>
                                </Link>
                            </div>
                            <div className="inline-block mx-32 font-bold">
                                <div><Trans i18nKey="gettingStartedAccessCode">Were you invited to a party?</Trans></div>
                                <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-full py-2 text-white" onClick={this.accessCode}><Trans i18nKey="accessCode">Access code</Trans></button>
                            </div>
                            <div className="inline-block font-bold">
                                <div><Trans i18nKey="gettingStartedLogin">Already have an account?</Trans></div>
                                <Link to="/signIn">
                                    <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-full py-2 text-white"><Trans i18nKey="login">Log in</Trans></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    ) 
  }
}
export default GettingStartedPage;

