import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import { tz } from 'moment-timezone';
import {reactLocalStorage} from 'reactjs-localstorage';

const guess = tz.guess(true);    // "Asia/Calcutta"
const zone = tz.zone(guess);     // return Zone object 

const firebaseConfig = {
    apiKey: "AIzaSyBNJ26wzzmVkzhJniXyA-znrv0LH1qSKWU",
    authDomain: "alaskan-love.firebaseapp.com",
    databaseURL: "https://alaskan-love.firebaseio.com",
    projectId: "alaskan-love",
    storageBucket: "alaskan-love.appspot.com",
    messagingSenderId: "920138631777",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.database();
export const firestore = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();
const providerFacebook = new firebase.auth.FacebookAuthProvider();
const providerApple = new firebase.auth.OAuthProvider('apple.com');
providerApple.addScope('email');

providerApple.addScope('name');

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const signInWithFacebook = () => {
  auth.signInWithPopup(providerFacebook);
};

export const signInWithApple = () => {
  //8H8K3VRD6H.com.bluepenguin.specialday
  // auth.signInWithPopup(providerApple);
  alert("Coming Soon");
};

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        let userDocument = {};
        db.ref(`/users/${uid}`).on('value', querySnapShot => {
          let data = querySnapShot.val() ? querySnapShot.val() : {};
          let todoItems = {...data};
          userDocument = todoItems;
          // const userDocument = await firestore.doc(`users/${uid}`).get();
          return {
            uid,
            ...userDocument,
          };
        });
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

const randomId = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const getFirstBrowserLanguage = () => {
  var nav = window.navigator,
      browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
      i,
      language,
      len,
      shortLanguage = null;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
          language = nav.languages[i];
          len = language.length;
          if (!shortLanguage && len) {
              shortLanguage = language;
          }
          if (language && len>2) {
              return language;
          }
      }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      //skip this loop iteration if property is null/undefined.  IE11 fix.
      if (language == null) { continue; } 
      len = language.length;
      if (!shortLanguage && len) {
          shortLanguage = language;
      }
      if (language && len > 2) {
          return language;
      }
  }

  return shortLanguage;
}

export const createEvent = async (event, user) => {
  // check we are passing everythign to createEvent
  if (!event || !user) return;
  const { groom, type, whoName } = event;
  try {
    var newEventKey = db.ref().child('events').push().key;
    let code = randomId(8).toUpperCase();
    await db.ref(`/events/${newEventKey}`).set({
      background: '#FFFFFF',
      bride : whoName,
      code: code,
      groom,
      primary: '#ff150b',
      secondary: '#000000',
      type: type,
    });
    await db.ref(`/eventCodes/${code}`).set(newEventKey);
  } catch (error) {
    console.error("Error creating user document", error);
  }
  return updateUserDocument(newEventKey, event, user);
};

export const setEventAddress = async (newEventKey, event) => {
  const { city, country, date, lat, lng, name, state, street, zip } = event;
  let newEventAddressKey = db.ref().child("events").child(newEventKey).child("addresses").push().key;
  await db.ref(`/events/${newEventKey}/addresses/${newEventAddressKey}`).set({
    date: date,
    timezone: zone.abbr(new Date().getTime()),
    latitude: lat,
    longitude: lng,
    city, 
    country,
    locationName: name, 
    name: name,
    state,
    street,
  });
}

export const updateEventAddress = async (eventId, addressId, event) => {
  const { city, country, lat, lng, name, state, street } = event;
  await db.ref(`/events/${eventId}/addresses/${addressId}`).update({
    latitude: lat,
    longitude: lng,
    city, 
    country,
    locationName: name, 
    name: name,
    state,
    street,
  });
}

export const updateUserDocument = async (eventId, event, user) => {
  if (!user) return;
  console.log(user);
  await db.ref(`/users/${user.uid}`).set({
    firstName: event.plannerName,
    lastName: '-',
    locale: getFirstBrowserLanguage(),
    device: 'web',
  });
  await db.ref(`/users/${user.uid}/${eventId}`).set({
    loginAuth: randomId(16),
    isAdmin: true,
    device: 'web'
  });
  return setEventAddress(eventId, event);
};

export const updateUserOnLogin = async (user) => {
  if (user) {
    db.ref(`/users/${user.uid}`).update({
      loginAuth: randomId(16),
      device: 'web',
      email: user.email,
      lastModified: Math.round(new Date().getTime() / 1000),
    });
  }
}

export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
};

export const getEventIdSearch = async (search) => {
  if (!search) return;
  const upperSearch = search.toUpperCase().trim();
  let key = null;
  await db.ref().child('events').orderByChild('code').equalTo(upperSearch).once('value', (snapshot) => {
    snapshot.forEach((data) => {
      key = data.key;
    });
  });
  if (key === null) {
    return false;
  }
  reactLocalStorage.set('eventId', key);
  // update the user sturcture

  return true;
}

export const getFirstLastSearch = async (user, firstName, lastName) => {
  if (!user || !firstName || !lastName) return;
  const firstNameLower = firstName.toLowerCase().trim();
  const lastNameLower = lastName.toLowerCase().trim();
  let familyId = null;
  let guestId = null;
  const eventId = reactLocalStorage.get('eventId');
  await db.ref().child('guests').child(eventId).once('value', (snapshot) => {
    snapshot.forEach((data) => {
      const familyKeys = Object.keys(data.val());
      familyKeys.forEach(familyKey => {
        if (familyKey !== 'rsvpReserved' && familyKey !== 'rsvpTotal' && familyKey !== 'rsvpCompleted') {
          const guest = data.val()[familyKey];
          if (guest.firstName.toLowerCase() === firstNameLower && guest.lastName.toLowerCase() === lastNameLower) {
            guestId = familyKey;
            familyId = data.key;
          }
        }
      });
    });
  });
  if (guestId === null) {
    return false;
  }
  reactLocalStorage.set('guestId', guestId);
  reactLocalStorage.set('familyId', familyId);
  // update the user id stuff
  await db.ref(`/users/${user.uid}`).set({
    firstName: firstName,
    lastName: lastName,
    locale: getFirstBrowserLanguage(),
  });
  await db.ref(`/users/${user.uid}/${eventId}`).set({
    familyId,
    loginAuth: randomId(16),
  });
  //need to add a variable for the user so that we know that user has been used already
  await db.ref(`/guests/${eventId}/${familyId}/${guestId}`).set({
    firstName,
    lastName,
    hasAccount: true
  });
  return true;
}

export const setRSVPAmount = async(amount) => {
  const eventId = reactLocalStorage.get('eventId');
  const familyId = reactLocalStorage.get('familyId');
  await db.ref(`/guests/${eventId}/${familyId}`).update({
    rsvpCompleted: true,
    rsvpReserved: Number(amount),
    rsvpTotal: 0,
  });
  return true;
}

export const postCommentDb = async(user, userDetails, eventId, comment) => {
  const userId = user.uid;
  var newCommentKey = db.ref().child('comments').child(eventId).push().key;
  await db.ref(`/comments/${eventId}/${newCommentKey}`).update({
    user: userId,
    type: "comment",
    comment: comment,
    first_name: userDetails.firstName,
    last_name: userDetails.lastName,
    posted: Math.floor(Date.now() / 1000)
  });
  return true;
}

export const addFamily = async(eventId, guests, rsvpReserved, rsvpTotal, rsvpCompleted) => {
  var newFamilyKey = db.ref().child('guests').child(eventId).push().key;
  guests.forEach(member => {
    var newMemberKey = db.ref().child('guests').child(eventId).child(newFamilyKey).push().key;
    db.ref().child('guests').child(eventId).child(newFamilyKey).child(newMemberKey).update({
      firstName: member.firstName.trim(),
      lastName: member.lastName.trim(),
    });
  });

  db.ref().child('guests').child(eventId).child(newFamilyKey).update({
    rsvpReserved: rsvpReserved,
    rsvpTotal: rsvpTotal,
    rsvpCompleted: rsvpCompleted
  });

  return true;
}

export const updateFamily = async(eventId, familyId, guests, rsvpReserved, rsvpTotal, rsvpCompleted) => {
  guests.forEach(member => {
    var memberKey = '';
    if (member.id && member.id.length > 0) {
      memberKey = member.id;
    } else {
      memberKey = db.ref().child('guests').child(eventId).child(familyId).push().key;
    } 
    db.ref().child('guests').child(eventId).child(familyId).child(memberKey).update({
      firstName: member.firstName.trim(),
      lastName: member.lastName.trim(),
    });
  });

  db.ref().child('guests').child(eventId).child(familyId).update({
    rsvpReserved: rsvpReserved,
    rsvpTotal: rsvpTotal,
    rsvpCompleted: rsvpCompleted
  });

  return true;
}

export const saveTableLayout = async (eventId, layout) => {
  layout.forEach(item => {
    delete item.isBounded;
    delete item.isDraggable;
    delete item.isResizable;
    delete item.maxH;
    delete item.maxW;
    delete item.minH;
    delete item.minW;
    delete item.moved;
    delete item.resizeHandles;
    delete item.static;
  });
  db.ref().child('tables').child(eventId).update({
    layout: layout,
  });

  return true;
}

export const addTableForGuest = async (eventId, familyId, guestId, tableNum, member) => {
  db.ref().child('guests').child(eventId).child(familyId).child(guestId).update({
    table: tableNum,
  });
  db.ref().child('tables').child(eventId).child(tableNum).child(guestId).update({
    firstName: member.firstName,
    lastName: member.lastName,
    familyId: familyId,
    guestId: guestId,
  });

  return true;
}

export const removeTableForGuest = async (eventId, familyId, guestId, tableNum) => {
  db.ref().child('guests').child(eventId).child(familyId).child(guestId).child('table').remove();
  db.ref().child('tables').child(eventId).child(tableNum).child(guestId).remove();
  return true;
}

export const updateRegistries = async (eventId, urls) => {
  db.ref().child("events").child(eventId).child("registries").set(urls);
}

export const updateStory = async (eventId, storyText, storyImg) => {
  let storyArray = {text: storyText};
  if(storyImg.length > 0) {
    storyArray = {text: storyText, img: storyImg};
  }
  db.ref().child("events").child(eventId).child("stories").child("story").update(storyArray);
}
