import React, { Component } from "react";
import { Link } from "@reach/router";
import { Trans } from "react-i18next";
import MenuIcon from '@material-ui/icons/Menu';

import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EventIcon from '@material-ui/icons/Event';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

class Header extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showing: false,
        }
    }
    render = () => {
        const { user } = this.props;
        
          const toggleDrawer = (open) => (event) => {
            if (
              event &&
              event.type === 'keydown' &&
              (event.key === 'Tab' || event.key === 'Shift')
            ) {
              return;
            }
        
            this.setState({ showing: open });
          };
        const list = () => (
            <div
                // className={clsx(classes.list)}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem button key="home">
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <Link to="/" className="hover:text-blue-600">
                    <Trans i18nKey="home">Home</Trans>
                  </Link>
                </ListItem>
                <ListItem button key="getting-started">
                  <ListItemIcon><DirectionsRunIcon /></ListItemIcon>
                  <Link to="/gettingStarted" className="hover:text-blue-600">
                  <Trans i18nKey="getStarted">Let's get started</Trans>
                  </Link>
                </ListItem>
              </List>
              <Divider />
              {user && (
                <>
                  <List>
                    <ListItem button key="my-events">
                      <ListItemIcon><EventIcon/></ListItemIcon>
                      <Link to="/welcome" className="hover:text-blue-600">
                        <Trans i18nKey="myEvents">My Events</Trans>
                      </Link>
                    </ListItem>
                    <ListItem button key="my-Profile">
                      <ListItemIcon><AccountBoxIcon/></ListItemIcon>
                      <Link to="/profile" className="hover:text-blue-600">
                        <Trans i18nKey="myProfile">My Profile</Trans>
                      </Link>
                    </ListItem>
                  </List>
                  <Divider />
                </>
              )}
            </div>
        );
        return <div>
            <div class="lg:hidden">
                <IconButton aria-label="delete" color="primary" onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor='left'
                    open={this.state.showing}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list()}
                </SwipeableDrawer>
            </div>
            <div class="hidden lg:block">
            <Link to="/"><img className="inline-block ml-8" src="images/logo_no_bg.png" loading="lazy" width="175" alt=""/></Link>
            {!user && (
                <nav className="inline-block float-right p-8">
                    <Link to="/" className="text-gray-500 hover:text-blue-600 ml-20">
                        <Trans i18nKey="home">Home</Trans>
                    </Link>
                    <Link to="/gettingStarted" className="text-gray-500 hover:text-blue-600 mx-20">
                        <Trans i18nKey="getStarted">Let's get started</Trans>
                    </Link>
                </nav>
            )}
            {user && (
                <nav className="inline-block float-right p-8">
                    <Link to="/" className="text-gray-500 hover:text-blue-600 ml-20">
                        <Trans i18nKey="home">Home</Trans>
                    </Link>
                    <Link to="/gettingStarted" className="text-gray-500 hover:text-blue-600 ml-20">
                        <Trans i18nKey="getStarted">Let's get started</Trans>
                    </Link>
                    <Link to="/welcome" className="text-gray-500 hover:text-blue-600 ml-20">
                        <Trans i18nKey="myEvents">My Events</Trans>
                    </Link>
                    <Link to="/profile" className="text-gray-500 hover:text-blue-600 mx-20">
                        <Trans i18nKey="myProfile">My Profile</Trans>
                    </Link>
                </nav>
            )}
            </div>
        </div>
    }
}
export default Header;