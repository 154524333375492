import React, { Component } from "react";
import { navigate } from "@reach/router";
import { createEvent } from "../firebaseConfig";
import { Trans } from "react-i18next";

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TextField from '@material-ui/core/TextField';
import DatePicker from '@material-ui/lab/DatePicker';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Header from "./Header";
import i18n from '../i18n';

const getLanguage = () => {
  const lang = i18n.language;
  const langCode = lang.split('-')[0];
  let currentLang = 'en';
  if(Object.keys(i18n.store.data).includes(langCode)) {
    currentLang = langCode;
  }
  return currentLang;
}

class CreateEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          date: null,
          time: null,
          plannerName: '',
          whoName: '',
          address: '',
          showEventDetails: false,
          error: null,
          open: false,
          openError: false,
          venueName: '',
          topEventType: '',
          eventType: '',
          groom: '',
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        // go to the new page
        navigate("/welcome");
        this.setState({
            open: false,
        });
    };

    handleErrorClose = () => {
        this.setState({
            openError: false,
        });
    }

    onChange = (field, value) => {
        if (field === 'date') {
            this.setState({
                date: value,
            });
        } else if (field === 'time') {
            this.setState({
                time: value,
            });
        } else if (field === 'plannerName') {
            this.setState({
                plannerName: value,
            });
        }
    }

    handleEventChange = (event) => {
        this.setState({
            eventType: event.target.value,
        });
    }

    onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'plannerName') {
            this.setState({
                plannerName: value,
            });
        } else if (name === 'whoName') {
            this.setState({
                whoName: value,
            });
        } else if (name === 'address') {
            this.setState({
                address: value,
            });
        } else if (name === 'venueName') {
            this.setState({
                venueName: value,
            });
        } else if (name === 'groom') {
            this.setState({
                groom: value,
            });
        }
    };

    selectEventType = (type) => {
        this.setState({
            topEventType: type,
            showEventDetails: true
        });
    }

    createNewEvent = () => {
        // check if all fields are filled
        const { date, eventType, groom, time, topEventType, plannerName, whoName, address, venueName } = this.state;
        const language = getLanguage();
        // check if event is filled
        if (topEventType === 'other' && eventType === null) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.createEventErrorNoType
              });
            return;
        }
        if (topEventType === 'wedding' || eventType === 'wedding') {
            if (groom.length === 0) {
                this.setState({
                    openError: true,
                    error: i18n.store.data[language].translation.createEventErrorNoGroom
                  });
                return;
            }
        }
        if (date == null || time == null || plannerName.length === 0 || whoName.length === 0 || address.length === 0 || time === 'Invalid Date' || venueName.length === 0) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.createEventErrorNoFields
            });
            return;
        }
        // check date
        const tmpDate = new Date(date);
        const currentDate = new Date();
        const year = tmpDate.getFullYear();
        if (isNaN(year)) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.createEventErrorInvalidDate
            });
            return;
        } else if (year > currentDate.getFullYear() + 5) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.createEventErrorMaxDate
            });
            return;
        } else if (year < currentDate.getFullYear()) {
            this.setState({
                openError: true,
                error: i18n.store.data[language].translation.createEventErrorMinData
            });
            return;
        }
        // paul check the address if filled and is valid via google (then show best guess)
        fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBii9DkRCProTurJ7zJMLSDkYh-mq2UCdk&address=" + address)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.results.length === 0) {
                    this.setState({
                        openError: true,
                        error: i18n.store.data[language].translation.createEventErrorAddressNoMatch
                    });
                    return;
                } else if (result.results.length === 1) {
                    const address = result.results[0];
                    const lat = address.geometry.location.lat;
                    const lng = address.geometry.location.lng;
                    const addressComponents = address.address_components;
                    let street = '';
                    let city = '';
                    let zip = '';
                    let country = '';
                    let state = '';
                    addressComponents.forEach(component => {
                        const types = component.types;
                        types.forEach(type => {
                            if (type === 'country') {
                                country = component.long_name;
                            } else if (type === 'postal_code') {
                                zip = component.long_name;
                            } else if (type === 'street_number') {
                                if (street.length > 0 ) {
                                    street = component.long_name + ' ' + street;
                                } else {
                                    street = component.long_name;
                                }
                            } else if (type === 'route') {
                                if (street.length > 0 ) {
                                    street = street + ' ' + component.long_name;
                                } else {
                                    street = component.long_name;
                                }
                            } else if (type === 'locality') {
                                city = component.long_name;
                            } else if (type === 'administrative_area_level_1') {
                                state = component.long_name;
                            } 
                        });
                    });
                    var type = topEventType;
                    if (topEventType === 'other') {
                        type = eventType;
                    }
                    // create linux timestamp from time
                    const timestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), 0).getTime()/1000;
                    const event = {
                        date: timestamp,
                        plannerName,
                        groom,
                        type,
                        lat,
                        lng,
                        street,
                        city,
                        zip,
                        country,
                        state,
                        name: venueName,
                        whoName,
                    }
                    createEvent(event, this.props.user);
                    this.handleClickOpen();
                    return;
                } else {
                    // multiple events do popup with select
                    this.setState({
                        openError: true,
                        error: i18n.store.data[language].translation.createEventErrorAddressManyMatch
                    });
                    return;
                }
            },
            (error) => {
                this.setState({
                    openError: true,
                    error: i18n.store.data[language].translation.somethingWrong
                });
                return;
            }
        );
    }

    render() {
        const {
            address, date, error, eventType, groom, open, openError, showEventDetails, plannerName, time, topEventType, venueName, whoName
        } = this.state;
        const language = getLanguage();
        return (
            <div>
                <Dialog
                    open={open}
                    disableBackdropClick
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Trans i18nKey="createEventSuccessful">Congratulations></Trans>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Trans i18nKey="createEventSuccessfulMsg">Your event has been created! Lets start by inviting your guests.</Trans>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleClose} autoFocus>
                            <Trans i18nKey="createEventSuccessfulBtn">Lets Get Started</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openError} onClose={this.handleErrorClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Trans i18nKey="error">Error</Trans></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleErrorClose} color="primary">
                            <Trans i18nKey="close">Close</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Header user={this.props.user}/>
                <div className="main-content relative">
                    <div className="absolute w-full h-full top-0">
                        <div className="h-3/5 m-auto text-center text-gray-500 mt-10">
                            { !showEventDetails && (
                                <div id="eventTypeDiv">
                                    <h1 className="text-4xl mb-4"><Trans i18nKey="createEvent">Create your event</Trans></h1>
                                    <div><Trans i18nKey="createEventWelcome">Welcome to Special day! Please select the kind of party you are hosting</Trans></div>
                                    <div className="mt-6">
                                        <div className="inline-block">
                                            <div className="text-xl"><Trans i18nKey="wedding">Wedding</Trans></div>
                                            <div className="w-60 mt-4"><Trans i18nKey="createEventWeddingDesc">Specially design for those bridezillas who like to have everything under control</Trans></div>
                                            <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-24 py-2 text-white"
                                            onClick={() => {
                                                this.selectEventType("wedding");
                                            }}><Trans i18nKey="select">Select</Trans></button>
                                        </div>
                                        <div className="inline-block mx-32">
                                            <div className="text-xl"><Trans i18nKey="quinceanos">Quince Años</Trans></div>
                                            <div className="w-60 mt-4"><Trans i18nKey="createEventQuinceDesc">To help every princess to make their day even more special</Trans></div>
                                            <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-24 py-2 text-white"
                                            onClick={() => {
                                                this.selectEventType("quinceanos");
                                            }}><Trans i18nKey="select">Select</Trans></button>
                                        </div>
                                        <div className="inline-block">
                                            <div className="text-xl"><Trans i18nKey="specialParty">SPECIAL PARTY</Trans></div>
                                            <div className="w-60 mt-4"><Trans i18nKey="createEventSpecialParty">The best option for birthday, baptism, communion, graduation, anniversary, baby shower or any other party.</Trans></div>
                                            <button className="mt-8 bg-blue-500 hover:bg-blue-600 w-24 py-2 text-white"
                                            onClick={() => {
                                                this.selectEventType("other");
                                            }}><Trans i18nKey="select">Select</Trans></button>
                                        </div>
                                    </div>
                                </div>)
                            }
                            { showEventDetails && (
                                <div id="eventDetailsDiv" className="pb-24">
                                    <h1 className="text-4xl mb-4">Event Details</h1>
                                    {topEventType === 'other' && (
                                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                            <TextField
                                                sx={{ width: 500 }}
                                                id="eventType"
                                                name="eventType"
                                                label={i18n.store.data[language].translation.createEventType}
                                                value={eventType}
                                                onChange={this.handleEventChange}
                                                select
                                            >
                                                <MenuItem value={'anniversary'}><Trans i18nKey="createEventTypeAnniversary">Anniversary</Trans></MenuItem>
                                                <MenuItem value={'babyshower'}><Trans i18nKey="createEventTypeBabyShower">Baby Shower</Trans></MenuItem>
                                                <MenuItem value={'baptism'}><Trans i18nKey="createEventTypeBaptism">Baptism</Trans></MenuItem>
                                                <MenuItem value={'birthday'}><Trans i18nKey="createEventTypeBirthday">Birthday</Trans></MenuItem>
                                                <MenuItem value={'communion'}><Trans i18nKey="createEventTypeCommunion">Communion</Trans></MenuItem>
                                                <MenuItem value={'graduation'}><Trans i18nKey="createEventTypeGraduation">Graduation</Trans></MenuItem>
                                                <MenuItem value={'quinceanos'}><Trans i18nKey="quinceanos">Quince Años</Trans></MenuItem>
                                                <MenuItem value={'personal'}><Trans i18nKey="createEventTypePersonalParty">Personal Party</Trans></MenuItem>
                                                <MenuItem value={'wedding'}><Trans i18nKey="wedding">Wedding</Trans></MenuItem>
                                            </TextField>
                                        </Stack>
                                    )}
                                    <Stack spacing={5} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label={i18n.store.data[language].translation.createEventDate}
                                                value={date}
                                                onChange={(newValue) => {
                                                    this.onChange('date', newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <TimePicker
                                                label={i18n.store.data[language].translation.createEventTime}
                                                value={time}
                                                onChange={(newValue) => {
                                                    this.onChange('time', newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    <Stack spacing={5} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                        <TextField
                                            required
                                            name="plannerName"
                                            label={i18n.store.data[language].translation.createEventPlannerFirst}
                                            sx={{ width: 230 }}
                                            value={plannerName}
                                            onChange={this.onChangeHandler}
                                        />
                                        <TextField
                                            required
                                            name="whoName"
                                            label={(topEventType === 'wedding' || eventType === 'wedding') ? i18n.store.data[language].translation.createEventBrideFirst : i18n.store.data[language].translation.createEventOtherFirst}
                                            sx={{ width: 230 }}
                                            value={whoName}
                                            onChange={this.onChangeHandler}
                                        />
                                    </Stack>
                                    {(topEventType === 'wedding' || eventType === 'wedding') && (
                                        <Stack spacing={5} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                            <TextField
                                                required
                                                name="groom"
                                                label={i18n.store.data[language].translation.createEventGroomFirst}
                                                sx={{ width: 230 }}
                                                value={groom}
                                                onChange={this.onChangeHandler}
                                            />
                                        </Stack>
                                    )}
                                    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                        <TextField
                                            sx={{ width: 500 }}
                                            required
                                            name="venueName"
                                            label={i18n.store.data[language].translation.createEventVenueName}
                                            value={venueName}
                                            onChange={this.onChangeHandler}
                                        />
                                    </Stack>
                                    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="mb-4">
                                        <TextField
                                            sx={{ width: 500 }}
                                            required
                                            multiline
                                            rows={3}
                                            name="address"
                                            label={i18n.store.data[language].translation.createEventAddress}
                                            value={address}
                                            onChange={this.onChangeHandler}
                                        />
                                    </Stack>
                                    <Button variant="contained"
                                    onClick={() => {
                                        this.createNewEvent();
                                    }}><Trans i18nKey="createEventBtn">Create Event</Trans></Button>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
}
export default CreateEventPage;

