import React, { Component, createContext } from "react";
import { auth, updateUserOnLogin } from "../firebaseConfig";

export const UserContext = createContext({ user: null, userDetails: null });

class UserProvider extends Component {
    state = {
      user: null,
    };
    componentDidMount = () => {
      auth.onAuthStateChanged(userAuth => {
        updateUserOnLogin(userAuth);
        this.setState({ user: userAuth });
      });
    };
    render() {
      return (
        <UserContext.Provider value={this.state.user}>
          {this.props.children}
        </UserContext.Provider>
      );
    }
}
export default UserProvider;