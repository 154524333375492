import React, { useState } from "react";
import { Link } from "@reach/router";
import { auth } from "../firebaseConfig";
import TextField from '@material-ui/core/TextField';

import { Trans } from "react-i18next";
import Header from "./Header";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = event => {
    const newValue = event.target.value;
    setEmail(newValue);
  };

  const sendResetEmail = event => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };
  return (
    <div className="mt-8">
      <Header/>
      <h1 className="text-xl text-center font-bold mb-3">
        <Trans i18nKey="resetPasswordTitle">Reset your Password</Trans>
      </h1>
      <div className="border border-blue-300 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        <form action="">
          {emailHasBeenSent && (
            <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
              <Trans i18nKey="resetPasswordSuccessful">An email has been sent to you!</Trans>
            </div>
          )}
          {error !== null && (
            <div className="py-3 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <TextField
            id="userEmail"
            type="email"
            label={<Trans i18nKey="resetPasswordEmail">Enter your email</Trans>}
            fullWidth
            variant="outlined"
            onChange={onChangeHandler}
            value={email}
            className="mb-30 w-full px-1 py-2"
          />
          <button
            className="w-full bg-blue-400 text-white py-3 mt-5"
            onClick={event => {
              sendResetEmail(event);
            }}
          >
            <Trans i18nKey="resetPasswordSend">Send me a reset link</Trans>
          </button>
        </form>
        <Link
         to ="/signIn"
          className="my-2 text-blue-700 hover:text-blue-800 text-center block"
        >
          &larr; <Trans i18nKey="resetPasswordBack">Back to sign in page</Trans>
        </Link>
      </div>
    </div>
  );
};
export default PasswordReset;